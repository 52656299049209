export const IG_DESCRIPTIONS: Record<
  string,
  {
    short: string;
    long: string;
    familyDescription?: string;
    publicTender?: string;
  }
> = {
  "IG-1": {
    short: "",
    long: "Riduzione % spesa per l’erogazione del servizio",
  },
  "IG-2": { short: "", long: "Riduzione % tempi di erogazione del servizio" },
  "IG-3": { short: "", long: "Numero servizi aggiuntivi offerti" },
  "IG-4": { short: "", long: "Obiettivi CAD raggiunti con l’intervento" },
  "IG-4-1": {
    short: "Digitalizzazione",
    long: "Digitalizzazione e dematerializzazione delle attività amministrativa con semplificazione dei procedimenti amministrativi",
  },
  "IG-4-2": {
    short: "Standard di qualità",
    long: "Innalzamento degli standard qualitativi dei servizi erogati tramite ricorso a soluzioni digitali, in termini di efficacia, efficienza ed economicità",
  },
  "IG-4-3": {
    short: "Accessibilità",
    long: "Maggiore accessibilità digitale ai servizi pubblici nel rispetto dei principi di uguaglianza, imparzialità e di non discriminazione",
  },
  "IG-4-4": {
    short: "Misurabilità",
    long: "Misurabilità delle perfomance delle Amministrazione nell’erogazione dei servizi",
  },
  "IG-4-5": {
    short: "Performance",
    long: "Analisi delle performance e individuazione delle aree di miglioramento",
  },
  "IG-4-6": {
    short: "Trasparenza",
    long: "Incremento della trasparenza in merito alle condizioni di accesso e utilizzo dei servizi digitali",
  },

  "IG-5-1": { short: "CIE", long: "IG-5-1 - CIE" },
  "IG-5-2": { short: "SPID", long: "IG-5-2 - SPID" },
  "IG-5-3": { short: "PagoPA", long: "IG-5-3 - PagoPA" },
  "IG-5-4": { short: "FatturaPA", long: "IG-5-4 - FatturaPA" },
  "IG-5-5": { short: "ANPR", long: "IG-5-5 - ANPR" },
  "IG-5-6": { short: "NoiPA", long: "IG-5-6 - NoiPA" },
  "IG-5-7": {
    short: "DB interesse nazionale",
    long: "IG-5-7 - DB di interesse nazionale",
  },
  "IG-5-8": { short: "Open Data", long: "IG-5-8 - Open Data" },
  "IG-5-9": {
    short: "Vocabolari controllati",
    long: "IG-5- 9 - Vocabolari controllati",
  },

  "IG-6-1": {
    short: "RNDT",
    long: "IG-6-1 - Repertorio nazionale dei dati territoriali  (RNDT)",
  },
  "IG-6-2": {
    short: "ANPR",
    long: "IG-6-2 - Anagrafe nazionale della popolazione residente (ANPR)",
  },
  "IG-6-3": {
    short: "BDNCP",
    long: "IG-6-3 - Banca dati nazionale dei contratti pubblici (BDNCP)",
  },
  "IG-6-4": {
    short: "Casellario Giudiziale",
    long: "IG-6-4 - Casellario Giudiziale",
  },
  "IG-6-5": { short: "RI", long: "IG-6-5 - Registro delle Imprese (RI)" },
  "IG-6-6": {
    short: "Archivi su immigrazione e asilo",
    long: "IG-6-6 - Archivi automatizzati in materia di immigrazione e asilo",
  },
  "IG-6-7": {
    short: "ANA",
    long: "IG-6-7 - Anagrafe nazionale degli assistiti (ANA)",
  },
  "IG-6-8": {
    short: "Anagrafe aziende agricole",
    long: "IG-6-8 - Anagrafe aziende agricole",
  },
  "IG-6-9": {
    short: "ANNCSU",
    long: "IG-6-9 - Archivio nazionale dei numeri civici delle strade urbane (ANNCSU)",
  },
  "IG-6-10": {
    short: "Base dati catastale",
    long: "IG-6-10 - Base dati catastale",
  },
  "IG-6-11": {
    short: "IPA",
    long: "IG-6-11 - Indice delle Pubbliche Amministrazioni (IPA)",
  },
  "IG-6-12": {
    short: "INI-PEC",
    long: "IG-6-12 - Indice nazionale degli indirizzi di posta elettronica certificata di professionisti e imprese (INI-PEC)",
  },
  "IG-6-13": {
    short: "PRA",
    long: "IG-6-13 - Pubblico registro automobilistico (PRA)",
  },
  "IG-6-14": {
    short: "Anagrafe Tributaria",
    long: "IG-6-14 - Anagrafe Tributaria",
  },
  "IG-6-15": {
    short: "Catalogo dati PA",
    long: "IG-6-15 - Catalogo dei dati delle Pubbliche Amministrazioni",
  },
  "IG-6-16": {
    short: "Catalogo servizi a cittadini e imprese",
    long: "IG-6-16 - Catalogo servizi a cittadini e imprese",
  },
  "IG-6-17": {
    short: "SINFI",
    long: "IG-6-17- Sistema informativo nazionale federato delle infrastrutture (SINFI)",
  },

  "IG-5": { short: "", long: "Infrastrutture immateriali integrate" },
  "IG-6": {
    short: "",
    long: "Integrazione con base dati di interesse nazionale",
  },
  "IG-7": {
    short: "",
    long: "Riuso di processi per erogazione servizi digitali",
  },
  "IG-8": { short: "", long: "Riuso soluzioni tecniche" },
  "IG-9": { short: "", long: "Collaborazione con altre amministrazioni" },
};