import ZoneObject from "../models/strapi/Zone";
import axios from "axios";
import { SD_BASE_API_URL, STRAPI_BASE_API_URL } from "../utils/Constants";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import News from "@/models/strapi/News";
import Media from "@/models/strapi/Media";
import DashboardItem from "@/models/strapi/DashboardItem";
import ZoneParameters from "@/models/strapi/ZoneParameters";
import { Realm } from "@/models";
import { httpStore } from "./typed";
import { RealmTags } from "@/mixins/http/RealmMixin";

@Module({
  stateFactory: true,
  namespaced: false,
  name: "zone",
})
export default class Zone extends VuexModule {
  _zone: ZoneObject | undefined = undefined;
  _news: News[] = [];
  _parameters: ZoneParameters | undefined = undefined;
  _realm: Realm | undefined = undefined;

  get zone(): ZoneObject | undefined {
    return this._zone;
  }

  get realm() {
    return this._realm;
  }

  get zoneParameters() {
    return this._parameters;
  }

  get news() {
    return this._news;
  }

  get newsById() {
    return (id: string) => this._news.find((o: News) => o.id === id);
  }

  get carouselImages() {
    return this._zone?.carousel_images.map((el: Media) => {
      return el as Media;
    });
  }

  get dashboardItems() {
    return this._zone?.dashboard_items.map((el: DashboardItem) => {
      return el as DashboardItem;
    });
  }

  get dashboardText() {
    return this._zone?.dashboard_text;
  }

  @Mutation
  setZone(payload: ZoneObject | undefined) {
    this._zone = Object.assign(new ZoneObject(), payload);
  }

  @Mutation
  setRealm(payload: Realm | undefined) {
    this._realm = payload;
  }

  @Mutation
  setZoneParameters(payload: ZoneParameters | undefined) {
    this._parameters = payload;
  }

  @Mutation
  setNews(news: News[]) {
    this._news = news;
  }

  @Action({ rawError: true })
  async loadNews() {
    const { commit } = this.context;

    try {
      const url = `${STRAPI_BASE_API_URL}/news`;
      const response = await axios.get(url);
      const news = response.data;
      if (!news) {
        throw new Error(`News not found`);
      }
      commit("setNews", news);
      return news;
    } catch (e) {
      commit("setNews", []);
      throw e;
    }
  }

  @Action({ rawError: true })
  async loadParameters() {
    const { commit } = this.context;
    try {
      const url = `${STRAPI_BASE_API_URL}/parameters`;
      const response = await axios.get(url);
      const parameters = response.data;
      if (!parameters) {
        throw new Error(`Parameters with code ${parameters} not found`);
      }
      commit("setZoneParameters", parameters);
      return parameters;
    } catch (e) {
      commit("setZoneParameters", undefined);
      throw e;
    }
  }

  @Action({ rawError: true })
  async loadZone() {
    const { commit } = this.context;

    try {
      const url = `${STRAPI_BASE_API_URL}/static-content`;
      const response = await axios.get(url);
      const zone = response.data;
      if (!zone) {
        throw new Error(`Zone with code ${zone} not found`);
      }
      commit("setZone", zone as ZoneObject);
      return zone;
    } catch (e) {
      commit("setZone", undefined);
      throw e;
    }
  }

  @Action({ rawError: true })
  async loadRealm() {
    const { commit } = this.context;

    try {
      const url = `${SD_BASE_API_URL}/realms/by_referer_code`;

      const realm = (await httpStore.request({
        tag: RealmTags.RealmByRefererCode,
        url: url,
        method: "GET",
      })) as Promise<Realm>;

      commit("setRealm", realm);
      return realm;
    } catch (e) {
      commit("setRealm", undefined);
      throw e;
    }
  }
}
