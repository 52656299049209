import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import Toasted from "vue-toasted";
import VueNumeric from "vue-numeric";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import VueStickyElement from "vue-sticky-element";
import ModelParserMixin from "@/mixins/ModelParser";
import RouteHelpersMixin from "@/mixins/RouteHelpers";
import RequestsMixin from "@/mixins/http/RequestsMixin";
import VIcon from "@/components/VIcon.vue";
import VSvg from "@/components/Common/VSvg.vue";
import DateTimeFilter from "@/filters/DateTime";
import VueScrollTo from "vue-scrollto";
import Spinner from "@/components/Common/Spinner.vue";
import LoadingError from "@/components/Common/LoadingError.vue";
import "@/initializers";
import "@/styles/app.scss";
import VueMq from "vue-mq";
import infiniteScroll from "vue-infinite-scroll";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueLayers from "vuelayers";
import Popper from "vue-popperjs";
import { printStore } from "@/store/typed";

declare module "vue/types/vue" {
  interface Vue {
    $mq: string;
    $parseModel(list: any | any[]): any;
    $getModelRoutes(name: string): any;
    $getRoute(name: string): any;
    $httpGet(
      url: string,
      tag: string,
      fields?: string[] | string
    ): Promise<any>;
    $httpPost(url: string, tag: string, data: any): Promise<any>;
    $httpPut(url: string, tag: string, data: any): Promise<any>;
    $httpDelete(url: string, tag: string, data: any): Promise<any>;
    $isRouteActive(route: string): boolean;
    $areRouteActive(routes: string[]): boolean;
  }
}
Vue.use(infiniteScroll);
Vue.config.productionTip = false;
Vue.use(VueScrollTo);
Vue.use(VueRouter);
Vue.use(VueNumeric);
Vue.use(VueMeta);
Vue.use(VueLayers);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 320,
    sm: 450,
    md: 998,
    lg: Infinity,
  },
  defaultBreakpoint: "sm", // customize this for SSR
});

Vue.component("v-svg", VSvg);
Vue.component("spinner", Spinner);
Vue.component("loading-error", LoadingError);

Vue.use(VueStickyElement);
window.addEventListener("load", () => {
  Vue.use(Toasted);
});

Vue.filter("currency", (value: number, currency: string) => {
  const formatter = new Intl.NumberFormat("IT", {
    style: "currency",
    currency: currency,
  });
  return formatter.format(value);
});

Vue.filter("numberToLocaleString", (value: number, locale: string) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter("dateTime", DateTimeFilter);

Vue.component("v-icon", VIcon);
Vue.mixin(ModelParserMixin);
Vue.mixin(RouteHelpersMixin);
Vue.mixin(RequestsMixin);

window.onbeforeprint = () => {
  // await new Promise(resolve => setTimeout(resolve, 1000));

  printStore.setPrinting(true);
};
window.onafterprint = () => {
  printStore.setPrinting(false);
};

Sentry.init({
  Vue,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "ppd-north.staging.monade.io",
        "ppd-center.staging.monade.io",
        "ppd-south.staging.monade.io",
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.component("popper", Popper);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
