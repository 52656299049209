
import { Vue, Component, Prop } from "vue-property-decorator";
import NavbarItem from "@/models/ui/NavbarItem";
import ClickOutside from "@monade/vue-components/src/directives/ClickOutside";
import { zoneStore } from "@/store/typed";

import customScroll from "@/initializers/ScrollToUtil";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class MobileSideMenu extends Vue {
  @Prop({ default: [] }) links!: NavbarItem[];

  private visible = false;

  get isVisible(): boolean {
    return this.visible;
  }

  get zone() {
    return zoneStore.zone;
  }

  async show() {
    this.visible = true;
    document.body.classList.add("overflow-hidden");
  }

  hide() {
    document.body.classList.remove("overflow-hidden");
    this.visible = false;
  }

  goOnSection(section: string) {
    customScroll.scroll(section);
    this.hide();
  }
}
