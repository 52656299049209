import Media from "./Media";
import Model from "../Model";

export default class Event extends Model {
  description!: string;
  starts_at!: Date;
  ends_at!: Date;
  name!: string;
  location!: string;
  cover!: Media;
  online!: boolean;
}
