import Vue from "vue";
import Vuex from "vuex";
import http from "./http";
import auth from "./auth";
import zone from "./zone";
import currentUser from "./currentUser";
import informative from "./informative";
import configurator from "./configurator";
import watcher from "./watcher";
import monitoring from "./monitoring";
import print from "./print";
import tags from "./tags";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "ppd",
  storage: window.localStorage,
  modules: ["auth", "configurator"],
});

export default new Vuex.Store({
  modules: {
    auth,
    currentUser,
    http,
    informative,
    print,
    zone,
    configurator,
    watcher,
    monitoring,
    tags,
  },
  plugins: [vuexPersist.plugin],
});
