import { Model, User } from "@/models";
import Company from "./strapi/Company";

export default class ContractCompany extends Model {
  static type = "contractCompanies";

  contractId!: string;
  companyId!: string;
  companyName!: string;
  share!: number;
}
