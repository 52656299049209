import { Model, Realm } from "@/models";

export default class DataPlot extends Model {
  static statuses: Array<{ id: string; class: string; label: string }> = [
    { id: "just_created", class: "primary", label: "Nuovo" },
    { id: "errors", class: "danger", label: "Fallito" },
    { id: "completed", class: "success", label: "Completato" },
    { id: "in_progress", class: "warning", label: "In esecuzione" },
  ];
  static type = "dataPlots";
  fileUrl!: string;
  current: boolean;
  fileName!: string;
  realm: Realm;
  status: string;
  validAt: Date;
  errorsMetadata: any;

  get hasErrors() {
    return this.status === "errors";
  }

  get statusLabel() {
    return DataPlot.statuses.find((o: any) => o.id === this.status)?.label;
  }

  get statusClass() {
    return DataPlot.statuses.find((o: any) => o.id === this.status)?.class;
  }
}

export class DataPlotPayload {
  file: any;
  realmId: string;
  validAt: Date;
}
