import Model from "./Model";
import ReviewMetadata from "./ReviewMetadata";

export default class Review extends Model {
  static legends: Array<{ vote: number; label: string }> = [
    { vote: 1, label: "Insufficiente" },
    { vote: 2, label: "Discreto" },
    { vote: 3, label: "Buono" },
    { vote: 4, label: "Ottimo" },
    { vote: 5, label: "Eccellente" },
  ];

  name!: string;
  metadata!: ReviewMetadata;
  total!: number;
  reviewableType!: string;
  reviewableId!: number;

  get isContractReview() {
    return (this.reviewableType = "Contract");
  }

  get isContractServiceReview() {
    return (this.reviewableType = "ContractService");
  }
}
