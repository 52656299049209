import { Model } from ".";
import { User } from ".";

export default class Topic extends Model {
  content!: string;
  user!: User;
  createdAt!: Date;
}

export interface TopicFormData {
  content: string;
  postId: string|number;
}
