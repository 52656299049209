import { render, staticRenderFns } from "./Logos.vue?vue&type=template&id=4c9268ba&scoped=true"
import script from "./Logos.vue?vue&type=script&lang=ts"
export * from "./Logos.vue?vue&type=script&lang=ts"
import style0 from "./Logos.vue?vue&type=style&index=0&id=4c9268ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c9268ba",
  null
  
)

export default component.exports