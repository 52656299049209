
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Spinner extends Vue {
  @Prop({ default: "sm" }) size!: string;

  get sizeClass() {
    return `size-${this.size}`;
  }
}
