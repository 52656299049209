
import { Vue, Component, Prop } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";

@Component({ components: { PageSection } })
export default class LoadingError extends Vue {
  notifyReload() {
    this.$emit("reload");
  }
}
