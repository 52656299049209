import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "../../utils/Constants";
import { Tag } from "@/models";
export enum TagMixinTags {
  TagIndex = "TagIndex",
}

@Component
export default class TagMixin extends Vue {
  getTags() {
    const url = `${SD_BASE_API_URL}/tags`;

    return httpStore.request({
      tag: TagMixinTags.TagIndex,
      url: url,
      method: "GET",
    }) as Promise<Tag[]>;
  }
}
