import axios from "axios";
import { STRAPI_BASE_API_URL } from "../utils/Constants";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import InformativeContent from "@/models/strapi/InformativeContent";
import { Event } from "../models";

@Module({
  stateFactory: true,
  namespaced: false,
  name: "informative",
})
export default class Informative extends VuexModule {
  _events: Event[] = [];
  _content: InformativeContent | undefined = undefined;

  get events(): Event[] {
    return this._events;
  }

  get content(): InformativeContent | undefined {
    return this._content;
  }

  get eventById() {
    return (id: string) => this._events.find((o: Event) => o.id === id);
  }

  @Mutation
  setContent(content: InformativeContent | undefined) {
    this._content = content;
  }

  @Mutation
  setEvents(events: Event[]) {
    this._events = events;
  }

  @Action({ rawError: true })
  async loadEvents() {
    const { commit } = this.context;

    try {
      const url = `${STRAPI_BASE_API_URL}/events`;
      const response = await axios.get(url);
      const events = response.data;
      if (!events) {
        throw new Error(`Events not found`);
      }
      commit("setEvents", events);
      return events;
    } catch (e) {
      commit("setEvents", []);
      throw e;
    }
  }

  @Action({ rawError: true })
  async loadContent() {
    const { commit } = this.context;
    try {
      const url = `${STRAPI_BASE_API_URL}/info-content`;
      const response = await axios.get(url);
      const content = response.data;
      if (!content) {
        throw new Error(`Content with code ${content} not found`);
      }
      commit("setContent", content);
      return content;
    } catch (e) {
      commit("setContent", undefined);
      throw e;
    }
  }
}
