import { RouteConfig } from "vue-router/types/router";
import AdminRoutes from "./Admin";

const routes: Array<RouteConfig> = [
  {
    meta: { role: ["admin", "user", "superadmin"] },
    path: "info",
    name: "info_home",
    component: () => import(/* webpackChunkName: "info" */ "../views/Info.vue"),
  },

  {
    path: "info/documenti",
    name: "documents",
    meta: { role: ["anyone"] },
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/Info/Documents.vue"),
  },
  {
    meta: { role: ["admin", "user", "superadmin"] },
    path: "project-management",
    name: "project_management_home",
    component: () =>
      import(
        /* webpackChunkName: "project-management" */ "../views/ProjectManagement.vue"
      ),
  },
  {
    meta: { role: ["admin", "superadmin"] },
    path: "watcher-area",
    name: "watcher_area_home",
    component: () =>
      import(
        /* webpackChunkName: "project-management" */ "../views/WatcherArea.vue"
      ),
  },
  {
    meta: { role: ["admin", "user", "superadmin"] },
    path: "monitoring-post-feed",
    name: "monitoring_post_feed",
    component: () =>
      import(
        /* webpackChunkName: "monitoring" */ "../views/Monitoring/PostFeed.vue"
      ),
  },
  {
    meta: { role: ["admin", "user", "superadmin"] },
    path: "monitoring-initiative-map",
    name: "monitoring_initiative_map",
    component: () =>
      import(
        /* webpackChunkName: "monitoring" */ "../views/Monitoring/InitiativeMap.vue"
      ),
  },
  {
    meta: { role: ["admin", "user", "superadmin"] },
    path: "monitoring-dashboard",
    name: "monitoring_dashboard",
    component: () =>
      import(
        /* webpackChunkName: "monitoring" */ "../views/Monitoring/Dashboard.vue"
      ),
  },
  {
    path: "project-management/iniziative/:id",
    name: "initiative_show",
    meta: { role: ["admin", "superadmin", "company_user"] },
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/Initiative/Show.vue"),
  },
  {
    path: "info/eventi/:id",
    name: "event_show",
    meta: { role: ["anyone"] },
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Event/Show.vue"),
  },
  {
    path: "info/eventi",
    name: "event_list",
    meta: { role: ["anyone"] },
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Event/List.vue"),
  },
  // {
  //   path: "info/configuratore",
  //   name: "info_configurator",
  //   meta: { role: ['anyone'] },
  //   component: () => import(/* webpackChunkName: "events" */ "../views/Info/Configurator.vue")
  // },
  {
    path: "info/servizi/:serviceId",
    name: "service_show",
    meta: { role: ["anyone"] },
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/Strapi/Service/Show.vue"
      ),
  },
  ...AdminRoutes,
];

export default routes;
