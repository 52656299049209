import { Model, User } from ".";

export enum PostEnumType {
  BreakingNews = "breaking_news",
  Webinar = "webinar",
  Event = "event",
}

export type PostType = Array<{ id: string; icon: string; label: string }>;

export default class Post extends Model {
  static types: PostType = [
    { icon: "it-horn", label: "Breaking News", id: "breaking_news" },
    { icon: "it-video", label: "Webinar", id: "webinar" },
    { icon: "it-pin", label: "Evento", id: "event" },
  ];

  static type = "posts";

  text!: string;
  user!: User;
  postType!: string;
  metadata!: any;
  imageUrl!: string;
  documentsUrls!: { url: string; name: string }[];
  topicsCount!: number;

  get isBreakingNews(): boolean {
    return this.postType === "breaking_news";
  }

  get isWebinar(): boolean {
    return this.postType === "webinar";
  }

  get isEvent(): boolean {
    return this.postType === "event";
  }
}

export class PostPayload extends Model {
  text!: string;
  postType!: string;
  metadata!: any;
  imageUrl!: string;
  documentsUrls!: string;
  image!: File;
  documents!: File;

  static empty(): PostPayload {
    const instance = new PostPayload();
    instance.text = null;
    instance.postType = null;
    instance.metadata = {};
    instance.imageUrl = null;
    instance.documentsUrls = null;
    instance.image = null;
    instance.documents = null;
    return instance;
  }
}
