import { RouteConfig } from "vue-router/types/router";

const administrativeRoles = ["superadmin", "admin", "company_user"];
const adminRoles = ["superadmin", "admin"];

const routes: Array<RouteConfig> = [
  {
    meta: { role: adminRoles },
    path: "admin",
    name: "admin_area",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
  },
  {
    meta: { role: adminRoles },
    path: "admin/users",
    name: "users_list",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/User/List.vue"),
  },
  {
    meta: { role: administrativeRoles },
    path: "admin/estimate_requests",
    name: "estimate_requests_list",
    component: () =>
      import(
        /* webpackChunkName: "estimateRequests" */ "../views/EstimateRequest/List.vue"
      ),
  },
  {
    meta: { role: adminRoles },
    path: "admin/organizations",
    name: "organizations_list",
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "../views/Organization/List.vue"
      ),
  },
  {
    meta: { role: adminRoles },
    path: "admin/data_plots",
    name: "data_plots_list",
    component: () =>
      import(/* webpackChunkName: "data_plots" */ "../views/DataPlot/List.vue"),
  },
  {
    meta: { role: adminRoles },
    path: "admin/companies",
    name: "companies_list",
    component: () =>
      import(/* webpackChunkName: "companies" */ "../views/Company/List.vue"),
  },
  {
    meta: { role: adminRoles },
    path: "admin/companies/:companyId",
    name: "companies_show",
    component: () =>
      import(/* webpackChunkName: "companies" */ "../views/Company/Show.vue"),
  },
  {
    meta: { role: adminRoles },
    path: "admin/services",
    name: "services_list",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Service/List.vue"),
  },
  {
    meta: { role: adminRoles },
    path: "admin/organizations/:organizationId",
    name: "organizations_show",
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "../views/Organization/Show.vue"
      ),
  },
  {
    meta: { role: adminRoles },
    path: "admin/realms",
    name: "realms_list",
    component: () =>
      import(/* webpackChunkName: "realms" */ "../views/Realm/List.vue"),
  },
  {
    meta: { role: adminRoles },
    path: "admin/realms/:realmId",
    name: "realms_show",
    component: () =>
      import(/* webpackChunkName: "realms" */ "../views/Realm/Show.vue"),
  },
  {
    meta: { role: administrativeRoles },
    path: "admin/gestione-attivazioni",
    name: "admin_initiatives_list",
    component: () =>
      import(
        /* webpackChunkName: "initiatives" */ "../components/ProjectManagement/Initiatives.vue"
      ),
  },
  {
    meta: { role: administrativeRoles },
    path: "admin/gestione-attivazioni/:id",
    name: "admin_initiative",
    component: () =>
      import(
        /* webpackChunkName: "initiatives" */ "../views/Initiative/_id.vue"
      ),
    children: [
      {
        path: "dettaglio",
        name: "admin_initiative_show",
        meta: { role: ["admin", "superadmin", "company_user"] },
        component: () =>
          import(
            /* webpackChunkName: "services" */ "../views/Initiative/Show.vue"
          ),
      },
      {
        path: "crea-contratto",
        name: "admin_contract_create",
        meta: { role: ["admin", "superadmin"] },
        component: () =>
          import(
            /* webpackChunkName: "services" */ "../views/Initiative/Contract/Create.vue"
          ),
      },
    ],
  },
  {
    meta: { role: administrativeRoles },
    path: "admin/gestione-contratti",
    name: "admin_contracts_list",
    component: () =>
      import(/* webpackChunkName: "contracts" */ "../views/Contract/List.vue"),
  },
  {
    meta: { role: ["anyone"] },
    path: "admin/gestione-contratti/:id",
    name: "admin_contract_show",
    component: () =>
      import(/* webpackChunkName: "contracts" */ "../views/Contract/Show.vue"),
  },
];

export default routes;
