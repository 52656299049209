import Media from "./Media";
import Model from "../Model";

export default class News extends Model {
  content!: string;
  date!: Date;
  title!: string;
  slug!: string;
  cover!: Media;
  created_at!: string;
}
