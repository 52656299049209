import { Model, Organization } from "@/models";
import Service from "./strapi/Service";

export type BadgeStatus = Array<{
  id: string;
  class: string;
  labels: Record<string, string>;
}>;

export class EstimateRequestPayload {
  serviceId?: string;
  organizationId?: string;
  initiativeId?: string;
  name?: string;
  message?: string;
}
export default class EstimateRequest extends Model {
  static type = "estimateRequests";

  static statuses: BadgeStatus = [
    { labels: { default: "Rifiutato" }, id: "rejected", class: "danger" },
    { labels: { default: "Accettato" }, id: "accepted", class: "success" },
    {
      labels: { default: "Stima effettuata" },
      id: "estimated",
      class: "warning",
    },
    { labels: { default: "Stima richiesta" }, id: "draft", class: "secondary" },
  ];

  service!: Service;
  organization!: Organization;
  status!: string;
  name!: string;
  estimatedAt!: Date;
  message!: string;
  answer!: string;
  estimateUrl!: string;
  initiativeId!: number;

  get statusLabel() {
    const status = EstimateRequest.statuses.find((o) => o.id === this.status);
    return status?.labels.default;
  }

  get statusClass() {
    const status = EstimateRequest.statuses.find((o) => o.id === this.status);
    return status?.class;
  }
}
