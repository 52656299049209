import { Model } from ".";
import { User } from "@/models";
import Media from "./strapi/Media";

export default class Event extends Model {
  static type = "events";

  title!: string;
  content!: string;
  scheduledAt!: Date;
  address!: string;
  user!: User;
  imageUrl: string;
  description: string;
  cover: Media;

  starts_at: Date;
  ends_at: Date;
}

export class EventPayload extends Model {
  title!: string;
  content!: string;
  scheduledAt!: string;
  address!: any;
  imageUrl!: string;
  image!: File;

  static empty(): EventPayload {
    const instance = new EventPayload();
    instance.title = null;
    instance.content = null;
    instance.address = null;
    instance.imageUrl = null;
    instance.scheduledAt = null;
    instance.image = null;
    return instance;
  }
}
