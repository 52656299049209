import { Model, User } from "@/models";

export default class Comment extends Model {
  static type = "comments";

  content!: string;
  read!: boolean;
  commentableType!: string;
  commentableId!: string;
  author: User;
}
