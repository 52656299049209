import { Service } from "@/models";
import BaseService from "@/models/configurator/BaseService";
import Service1 from "@/models/configurator/Service1";
import Service2 from "@/models/configurator/Service2";
import Service3 from "@/models/configurator/Service3";
import ZoneParameters from "@/models/strapi/ZoneParameters";
import { zoneStore } from "@/store/typed";
import simulationService from "@/service/SimulationService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Simulation from "@/models/Simulation";
import Throttler from "@/utils/Throttler";

@Module({
  stateFactory: true,
  namespaced: false,
  name: "configurator",
})
export default class Configurator extends VuexModule {
  private _simulation: Simulation | undefined = undefined;
  private _service1: Service1 | undefined = undefined;
  private _service2: Service2 | undefined = undefined;
  private _service3: Service3 | undefined = undefined;
  private _fieldLabel: string | undefined = undefined;
  private throttler: Throttler = new Throttler();

  get services(): (Service1 | Service3 | Service2)[] {
    return [this.service1, this.service2, this.service3];
  }

  get service1(): Service1 {
    return Object.assign(new Service1(), this._service1);
  }

  get service2(): Service2 {
    return Object.assign(new Service2(), this._service2);
  }

  get service3(): Service3 {
    return Object.assign(new Service3(), this._service3);
  }

  get simulation(): Simulation {
    return Object.assign(new Simulation(), this._simulation);
  }

  get fieldLabel(): string {
    return this._fieldLabel;
  }

  get alreadyInitialized() {
    return this.service1 || this.service2 || this.service3;
  }

  @Mutation
  setFieldLabel(payload: string | undefined) {
    this._fieldLabel = payload;
  }

  @Mutation
  setService1(payload: Service1 | undefined) {
    this._service1 = payload;
  }

  @Mutation
  setService2(payload: Service2 | undefined) {
    this._service2 = payload;
  }

  @Mutation
  setService3(payload: Service3 | undefined) {
    this._service3 = payload;
  }

  @Mutation
  setSimulation(payload: Simulation | undefined) {
    this._simulation = payload;
  }

  @Mutation
  checkService3Accessibility() {
    this._service3.disabled = !(
      this._service1.complete && this._service2.complete
    );
  }

  @Action({ rawError: true })
  async updateService1(service: Service1) {
    const { commit, dispatch } = this.context;
    commit("setService1", service);
    dispatch("updateSimulation", { service1: service.serialize() });
    commit("checkService3Accessibility", service);
  }

  @Action({ rawError: true })
  async updateService2(service: Service2) {
    const { commit, dispatch } = this.context;
    commit("setService2", service);
    dispatch("updateSimulation", { service2: service.serialize() });
    commit("checkService3Accessibility", service);
  }

  @Action({ rawError: true })
  async updateService3(service: Service3) {
    const { commit, dispatch } = this.context;
    commit("setService3", service);
    dispatch("updateSimulation", { service3: service.serialize() });
  }

  @Action({ rawError: true })
  async loadSimulation() {
    const { commit } = this.context;
    this.throttler.run(async () => {
      const simulation = await simulationService.getSimulation();
      commit("setSimulation", simulation);
    });
  }

  @Action({ rawError: true })
  async initialize(parameters: ZoneParameters) {
    const { commit, dispatch } = this.context;

    const simulation = await simulationService.getSimulation();
    commit("setSimulation", simulation);
    dispatch("createFromSimulation", { simulation, parameters });
  }

  @Action({ rawError: true })
  async updateSimulation(payload: any) {
    if (!this.simulation) {
      return;
    }
    const data = { metadata: Object.assign(this.simulation.metadata, payload) };
    if (!data.metadata.service1) {
      data.metadata.service1 = {};
    }

    if (!data.metadata.service2) {
      data.metadata.service2 = {};
    }

    if (!data.metadata.service3) {
      data.metadata.service3 = {};
    }

    const simulation = await simulationService.updateSimulation(data);
    this.setSimulation(simulation);
  }

  @Action({ rawError: true })
  async reset() {
    const { commit, dispatch } = this.context;
    const parameters = zoneStore.zoneParameters;
    const simulation = await simulationService.closeSimulation();
    commit("setSimulation", simulation);
    dispatch("createFromSimulation", { simulation, parameters });
  }

  @Action({ rawError: true })
  createFromSimulation(payload: {
    simulation: Simulation;
    parameters: ZoneParameters;
  }) {
    const { commit, dispatch } = this.context;
    const { simulation, parameters } = payload;
    commit("setSimulation", simulation);

    const service1 = new Service1();
    service1.deserialize(simulation.metadata.service1);
    service1.dailyCost = parameters.daily_cost_s1;
    commit("setService1", service1);

    const service2 = new Service2();
    service2.deserialize(simulation.metadata.service2);
    service2.dailyCost = parameters.daily_cost_s2;
    commit("setService2", service2);

    const service3 = new Service3();
    service3.deserialize(simulation.metadata.service3);
    service3.dailyCost = parameters.daily_cost_s3;
    commit("setService3", service3);

    commit("setFieldLabel", parameters.third_field_label);
  }
}
