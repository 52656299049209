export function chunkArrayInGroups(arr: any[], size: number): any[] {
  const myArray: any[] = [];
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export function groupBy(array: any[], key: string) {
  return array.reduce((value, x) => {
    (value[x[key]] = value[x[key]] || []).push(x);
    return value;
  }, {});
}
