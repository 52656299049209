
import { Vue, Component, Prop } from "vue-property-decorator";
import NavbarItem from "../../models/ui/NavbarItem";
import UserNavbarDropdown from "@/components/Common/UserNavbarDropdown.vue";
import { currentUserStore } from "@/store/typed";
import ClickOutside from "@/directives/ClickOutside";

@Component({
  components: {
    UserNavbarDropdown,
  },
  directives: {
    ClickOutside,
  },
})
export default class MobileSlimNavbar extends Vue {
  @Prop() readonly links!: NavbarItem[];

  private open = false;

  toggleOpen() {
    this.open = !this.open;
  }

  closeDropdown() {
    this.open = false;
  }

  private showLinks = false;

  get logged() {
    return currentUserStore.currentUser;
  }

  private setShowLinks(value: boolean) {
    this.showLinks = value;
  }

  private closeLinks() {
    this.showLinks = false;
  }
}
