import { Component, Vue } from "vue-property-decorator";
import {
  STRAPI_BASE_API_URL,
  STRAPI_MEDIA_BASE_API_URL,
} from "@/utils/Constants";
import { httpStore } from "@/store/typed";
import { Service } from "@/models";
import qs from "qs";
import FaqDomain from "@/models/strapi/FaqDomain";

export enum FaqsMixinTags {
  DomainsIndex = "DomainsIndex",
}

@Component
export default class FaqsMixin extends Vue {
  async getDomains(data: any): Promise<FaqDomain[]> {
    const url = `${STRAPI_BASE_API_URL}/faq-domains`;

    const response = await httpStore.request({
      tag: FaqsMixinTags.DomainsIndex,
      url: url,
      method: "GET",
    });

    return response as Promise<FaqDomain[]>;
  }
}
