export default class WatcherAreaData {
  quantitativeIndicators!: any;
  qualitativeIndicators!: any;
  collaborationIndicators!: any;
  digitalSpecificIndicators!: any;

  constructor(
    quantitative?: any,
    qualitative?: any,
    collaboration?: any,
    digitalSpecific?: any
  ) {
    this.quantitativeIndicators = quantitative || null;
    this.qualitativeIndicators = qualitative || null;
    this.collaborationIndicators = collaboration || null;
    this.digitalSpecificIndicators = digitalSpecific || null;
  }
}
