import { Document, User } from "@/models";
import { DocumentStatus } from "../Document";
export default class ExecutiveContract extends Document {
  static type = "documentExecutiveContracts";

  static statuses: DocumentStatus = [...Document.statuses];

  statusLabel(user: User) {
    const status = ExecutiveContract.statuses.find((o) => o.id === this.status);
    return status?.labels[user.role] || status?.labels.default;
  }

  get statusClass() {
    const status = ExecutiveContract.statuses.find((o) => o.id === this.status);
    return status?.class;
  }

  get initiativeStatus() {
    const withoutSuperClass = ExecutiveContract.type.replace("document", "");
    return (
      withoutSuperClass.charAt(0).toLocaleLowerCase() +
      withoutSuperClass.slice(1)
    );
  }
}
