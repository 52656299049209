import ContractService from "./ContractService";
import Model from "./Model";
import TimeTracking from "./TimeTracking";

export default class Task extends Model {
  static type = "tasks";

  name!: string;
  startDate!: Date | string;
  endDate!: Date | string;
  description!: string;
  contractServiceId!: string;
  duration!: number;
  progress!: number;
  modelType!: string;
  contractService!: ContractService;
  comments!: Comment[];

  days!: number;
  value!: number;

  timeTrackings!: TimeTracking[];

  constructor(...args: any[]) {
    super(...args);

    this.value = parseFloat(this.value as any);
  }

  static empty(): Task {
    const data = new Task();
    data.name = null;
    data.description = null;
    data.startDate = null;
    data.endDate = null;
    data.contractServiceId = null;
    data.timeTrackings = null;
    data.value = 0;

    return data;
  }

  get durationToDays() {
    return Math.floor(this.duration / 3600 / 24 / 1000);
  }
}
