
import { Vue, Component } from "vue-property-decorator";
import TheNavbar from "@/components/Common/TheNavbar.vue";
import TheFooter from "@/components/Common/TheFooter.vue";
import { zoneStore, currentUserStore } from "@/store/typed";
import { safeAsync } from "@/utils/AsyncUtil";

@Component({ components: { TheNavbar, TheFooter } })
export default class Container extends Vue {
  async created() {
    const [zone, zoneErrors] = await safeAsync(zoneStore.loadZone());
    const [news, newsErrors] = await safeAsync(zoneStore.loadNews());
    const [parameters, parametersErrors] = await safeAsync(
      zoneStore.loadParameters()
    );
  }

  get logged() {
    return !!currentUserStore.currentUser;
  }
}
