import axios from "axios";
import { STRAPI_BASE_API_URL } from "../utils/Constants";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import News from "@/models/strapi/News";
import Media from "@/models/strapi/Media";
import DashboardItem from "@/models/strapi/DashboardItem";
import ZoneParameters from "@/models/strapi/ZoneParameters";

@Module({
  stateFactory: true,
  namespaced: false,
  name: "print",
})
export default class Zone extends VuexModule {
  _printing = false;
  _toHide: string[] = [];

  get printing(): boolean {
    return this._printing;
  }

  get toHide(): string[] {
    return this._toHide;
  }

  @Mutation
  setPrinting(payload: boolean) {
    this._printing = payload;
  }

  @Mutation
  setToHide(payload: string[]) {
    this._toHide = payload;
  }
}
