export const IS_DESCRIPTIONS: Record<
  string,
  {
    short: string;
    long: string;
    familyDescription?: string;
    publicTender?: string;
  }
> = {
  IS1: {
    short: "",
    long: "Miglioramento Servizi digitalizzati: Nr servizi al cittadino digitalizzati/Nr totale dei servizi al cittadino",
  },
  IS2: {
    short: "",
    long: "Miglioramento dell’esperienza del cittadino dei servizi realizzati/modificati",
  },
  IS3: {
    short: "",
    long: "Standardizzazione Strumenti per la generazione e diffusione dei servizi digitali: % componenti di navigazione e interfaccia standard ed usabili / totale componenti",
  },
  IS4: {
    short: "",
    long: "Riusabilità-Co-working Soluzioni Applicative realizzate e/o adottate : Nr di progetti in riuso o Co-working /NrTotale Progetti di digitalizzazione ove è applicabile il Riuso o Co-working",
  },
  IS5: {
    short: "",
    long: "Innalzamento livello di interoperabilità: Nr di progetti conformi alle Linee Guida di Interoperabilità e nel rispetto del ONCE ONLY Principle/ Nr Progetti Realizzati",
  },
  IS6: {
    short: "",
    long: "Potenziamento Infrastrutture IT - adozione sistematica del paradigma Cloud: Nr di progetti conformi al paradigma Cloud/ Nr Totale di progetti realizzati",
  },
  IS7: {
    short: "",
    long: "Utilizzo Piattaforme Abilitanti: Nr progetti che integrano Piattaforme Abilitanti/Nr progetti ove è applicabile un’integrazione con le Piattaforme Abilitanti",
  },
  IS8: {
    short: "",
    long: "Miglioramento dell’utilizzo dei Servizi Digitali: Nr attività totali di prenotazioni on line (in tutte le modalità) /Nr attività totali (per attività si intendono: Prenotazioni CUP, Pagamenti online, Cambio del Medico, …)",
  },
  IS9: {
    short: "",
    long: "Standardizzazione dei documenti in ambito Sanità Digitale: % Documenti sanitari in linea con le direttive Nazionali ed Europee / totale documenti sanitari",
  },
};