
import { Vue, Component } from "vue-property-decorator";
import SocialLink from "@/models/strapi/SocialLink";
import { zoneStore } from "@/store/typed";

@Component({})
export default class SocialLinks extends Vue {
  get socialLinks(): SocialLink[] | undefined {
    return zoneStore.zone?.social_icons;
  }

  get show() {
    return this.socialLinks?.length;
  }

  svgIcon(id: string) {
    return `${require("@/assets/icons/sprite.svg")}#${id}`;
  }
}
