import { Company } from ".";
import Model from "./Model";
import Organization from "./Organization";

export class UserPayload {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  password: string;
  id: string;
  organizationId: string;
  businessUnit: string;
  companyId: string;

  constructor(user: User) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.id = user.id;
    this.role = user.role;
    this.businessUnit = user.businessUnit;
    this.organizationId = user.organization?.id;
    this.companyId = user.company?.id;
  }

  static empty(): UserPayload {
    return {
      id: null,
      firstName: null,
      lastName: null,
      role: "",
      email: "",
      businessUnit: null,
      password: "",
      organizationId: null,
      companyId: null,
    };
  }
}

export default class User extends Model {
  static type = "users";

  static adminRoles: Array<{ name: string; id: string }> = [
    { name: "SuperAdmin", id: "superadmin" },
    { name: "Admin", id: "admin" },
  ];

  static roles: Array<{ name: string; id: string }> = [
    ...User.adminRoles,
    { name: "Utente PA", id: "organization_user" },
    { name: "Utente Consip", id: "consip_user" },
    { name: "Utente RTI", id: "company_user" },
    { name: "Utente OCC", id: "occ_user" },
  ];

  email!: string;
  firstName!: string;
  lastName!: string;
  password!: string;
  role!: string;
  businessUnit!: string;
  active!: boolean;
  confirmedAt?: Date;
  companyOrOrganization?: string;
  organization?: Organization;
  company?: Company;
  organizationName?: string;

  fullname(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get label(): string {
    const companyLabel = this.companyOrOrganization
      ? ` (${this.companyOrOrganization})`
      : "";
    return `${this.firstName} ${this.lastName}${companyLabel}`;
  }

  static getRoleLabel(role: string): string | undefined {
    return this.roles.find((o) => o.id === role)?.name;
  }

  isOneOf(roles: string[]): boolean {
    return roles.includes(this.role);
  }

  get isAdministrative() {
    return this.isOneOf(["superadmin", "admin", "company_user"]);
  }

  get isAdmin(): boolean {
    return this.role === "admin";
  }

  get isSuperAdmin(): boolean {
    return this.role === "superadmin";
  }

  get isOrganizationUser(): boolean {
    return this.role === "organization_user";
  }

  get isCompanyUser(): boolean {
    return this.role === "company_user";
  }

  get isApplicationAdmin(): boolean {
    return this.isAdmin || this.isSuperAdmin;
  }

  get isOccUser(): boolean {
    return this.role === "occ_user";
  }

  get isConsipUser(): boolean {
    return this.role === "consip_user";
  }

  static get selectableRoles() {
    return User.roles.filter((o: any) => o.id !== "superadmin") || [];
  }

  toString() {
    return this.label;
  }
}
