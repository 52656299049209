import { User } from ".";
import Model from "./Model";

export default class TimeTracking extends Model {
  static type = "timeTrackings";

  user!: User;
  value!: number;
  percentage!: number;
  createdAt!: Date;

  get valueToInteger() {
    if (!this.value) {
      return null;
    }

    return Math.floor(this.value);
  }
}
