export const IQ_DESCRIPTIONS: Record<
  string,
  {
    short: string;
    long: string;
    familyDescription?: string;
    publicTender?: string;
  }
> = {
  "IQ-1": {
    short: "RSER - Impegni rispettati in offerta tecnica",
    long: "L’indicatore di qualità verifica il numero di impegni rispettati dal Fornitore in offerta tecnica, afferenti obbligazioni contrattuali non adempiute nei tempi e/o nei modi rappresentati nel Contratto Quadro e/o nei Contratti esecutivi con relativi allegati e/o tracciati sui Piani di lavoro, siano esse presidiate da specifici indicatori o non presidiate.",
  },
  "IQ-2": {
    short: "PFI - Personale inadeguato",
    long: "L’indicatore misura la quantità di risorse non rispondenti ai profili professionali richiesti.",
  },
  "IQ-3": {
    short: "TIP - Tempestività nell'inserimento di personale",
    long: "Con questo indicatore si misura la tempestività nell’inserimento/sostituzione delle risorse impiegate nella fornitura, compresi i Referenti.",
  },
  "IQ-4": {
    short: "RSCT - Rispetto di una scadenza contrattuale",
    long: "L’indicatore misura il rispetto di scadenze temporali derivanti dalla documentazione contrattuale inclusa l’offerta tecnica del fornitore e/o pianificate in un piano di lavoro approvato.",
  },
  "IQ-5": {
    short: "MAPP - Mancata Approvazione di un Artefatto della Fornitura",
    long: "L’indicatore misura l’inadeguatezza o incompletezza di Artefatti soggetti ad approvazione quali i prodotti del processo di sviluppo software: documento di Stima, Piano di Lavoro, Documento dei Requisiti, Analisi, Disegno, Documento di Architettura, Rapporti di Esecuzione Test, ecc... La mancata approvazione è equiparata a mancata consegna. Il ritardo sulla riconsegna del documento viene misurata dagli specifici indicatori di ogni servizio.",
  },
  "IQ-6": {
    short: "VQF - Valutazione Qualità della Fornitura",
    long: "L’indicatore costituisce un indice sintetico della qualità misurata e percepita del contratto esecutivo, rilevato secondo quanto specificato al par. “Rilevazione della Qualità della Fornitura” del CTS.",
  },
  "IQ-7": {
    short: "RLFN - Rilievi sulla fornitura",
    long: "L’indicatore conteggia le non conformità rilevate dall’Amministrazione per obbligazioni contrattuali non adempiute nei tempi e nei modi previsti, siano esse rilevate da specifici indicatori o non conformità, non presidiati da specifici indicatori.",
  },
  "IQ-8": {
    short: "MIDG - Monitoraggio indicatori di digitalizzazione",
    long: "L’indicatore misura il rispetto delle tempistiche nella raccolta delle informazioni ai fini della determinazione degli indici di digitalizzazione di cui al paragrafo 0",
  },
  "IQ-9": {
    short: "TAI - Tempo di Attivazione degli Interventi",
    long: "L’indicatore TAI misura la tempestività di attivazione degli interventi relativi ai servizi previsti nel Contratto Esecutivo, a partire dalla richiesta dell’Amministrazione.",
  },
  "IQ-10": {
    short: "INPF - Indisponibilità Portale di Fornitura",
    long: "L’indicatore verifica gli eventi imputabili al Fornitore, che non assicurino la disponibilità, la raggiungibilità tramite Internet in una logica multicanale, e la piena operatività del “Portale della Fornitura”, tali da non consentire alle singole Amministrazioni ed agli Organismi di coordinamento e controllo di attivare e governare agevolmente i servizi.",
  },
  "IQ-11": {
    short: "ATPF - Mancata Attivazione Portale di Fornitura",
    long: "L’indicatore verifica l’attivazione del “Portale della Fornitura”.",
  },
  "IQ-12": {
    short: "RSPL - Rispetto del Piano di lavoro di obiettivo",
    long: "L’indicatore RSPL verifica il rispetto della pianificazione di ogni obiettivo, misurando il rispetto della scadenza temporale di ciascuna milestone quali ad esempio: la date di consegna degli artefatti (la data pianificata di consegna del documento dei requisiti, del documento di analisi e piano di test, del documento di re-design, e così via per ogni artefatto obbligatorio del servizio); la data di consegna di ogni artefatto aggiuntivo offerto in offerta tecnica e concordato con l’Amministrazione nel Piano di lavoro; per i cicli agili, ogni data pianificata nello Sprint Planning; la data pianificata di “pronti al collaudo”, la data pianificata di termine collaudo con esito positivo, la data pianificata di rilascio, la data pianificata di termine avvio in esercizio formalmente.",
  },
  "IQ-13": {
    short: "GSCO - Giorni di sospensione del collaudo",
    long: "Il presente indicatore è il più rilevante per i servizi realizzativi in quanto la sospensione del collaudo è indice di una grave carenza qualitativa e incompletezza delle attività realizzative. È un fattore molto grave e il fornitore deve mettere in campo tutte le azioni di mitigazione, prevenzione e correzione per ridurre al minimo il rischio di sospensione previste nei vari Piani di Qualità (Generale di Lotto, Specifico di Contratto Esecutivo, di Obiettivo).",
  },
  "IQ-14": {
    short: "DAES - Difettosità in avvio in esercizio",
    long: "Il presente indicatore rileva la difettosità residua funzionale e non funzionale.",
  },
  "IQ-15": {
    short: "CTFU - Copertura test funzionali",
    long: "Tale indicatore ha lo scopo di verificare la completezza funzionale del Piano di Test a partire dal primo rilascio del documento in fase di analisi.",
  },
  "IQ-16": {
    short: "RIUSO - Riuso di component",
    long: "L’indicatore si applica ai progetti di sviluppo ex-novo e misura il grado di riuso per incorporamento e integrazione di servizi, componenti sw, strutture logiche di dati e funzionalità provenienti da fonti diversi (quali ad esempio parco software e servizi infrastrutturali dell’Amministrazione, catalogo del riuso di AgID, Developers Italia, open source, soluzioni di terze parti con licenza libera).",
  },
  "IQ-17": {
    short: "TRCG - Tempestività di Ripristino dell'Operatività in collaudo ed in garanzia",
    long: "Il presente livello di servizio si applica a: \n- Non conformità funzionali e non funzionali rilevate durante il collaudo. \n- Non conformità funzionali e non funzionali rilevate nel periodo di esercizio di funzionalità/servizi rilasciati dal fornitore e dunque in garanzia (le anomalie rilevate nella fase di Avvio in Esercizio dell’obiettivo realizzativo sono considerate unicamente nell’indicatore DAES e pertanto sono escluse dal presente indicatore); \n- Non conformità funzionali e non funzionali rilevate nel periodo di post-erogazione ed entro il periodo di garanzia: il software e l’ambiente di test dovranno essere mantenuti dal fornitore presso la propria struttura sino alla scadenza della garanzia.",
  },
  "IQ-18": {
    short: "VISS - Violazioni degli standard di sviluppo",
    long: "Gli standard di sviluppo e le linee guida saranno definiti a valle del periodo di avviamento e pertanto l’indicatore si applicherà esclusivamente dopo la condivisione dei suddetti standard tra il Fornitore e l’Amministrazione.",
  },
  "IQ-19": {
    short: "TROR - Totale Rilievi Obiettivo Realizzativo",
    long: "L’indicatore conteggia il numero di Rilievi degli obiettivi realizzativi derivanti da: \n- violazioni di soglia di specifici indicatori \n- ulteriori Rilievi per obbligazioni contrattuali non adempiute nei tempi e nei modi rappresentati nella documentazione contrattuale e non presidiati da specifici indicatori.",
  },
  "IQ-20": {
    short: "DFCC - DIFETTOSITÀ IN COLLAUDO",
    long: "L’indicatore misura il numero di non conformità bloccanti rilevate in collaudo.",
  },
  "IQ-21": {
    short: "MDTE -MIGLIORAMENTO DEBITO TECNICO (MODULI PREESISTENTI)",
    long: "La misura standard CISQ Debito tecnico stima il lavoro aggiuntivo finalizzato alle eliminazioni delle non conformità del software (rilevate tramite analisi statica). L’indicatore si applica a tutti gli obiettivi che modificano il codice esistente e deve essere calcolato anche per la manutenzione correttiva. Pertanto, con riferimento alla rilevazione di inizio fornitura (subentro) o alla presa in carico del software oggetto di modifica il fornitore rileva il debito tecnico pre-esistente all’intervento di modifica e si impegna a non peggiorarlo.",
  },
  "IQ-22": {
    short: "QNFU - QUALITÀ NON FUNZIONALE",
    long: "Qualità del software misurata attraverso le caratteristiche descritte in Tabella 2 - Caratteristiche non funzionali. La tabella riepiloga i livelli minimi da raggiungere per le caratteristiche e fornisce alcune indicazioni specifiche che dovranno essere riferite in misura equivalente dal Fornitore nell’utilizzo degli strumenti di rilevazione.",
  },
  "IQ-23": {
    short: "TROI - Tempestività di Ripristino dell'Operatività in esercizio",
    long: "Tempestività di ripristino dell'operatività in esercizio a seguito di malfunzionamenti differenziata per categoria di malfunzionamento",
  },
  "IQ-24": {
    short: "CSR - Interventi di manutenzione correttiva recidivi",
    long: "Misura il grado di efficacia degli interventi di manutenzioni misurando la percentuale di malfunzionamenti che si ripresentano dopo l’intervento correttivo",
  },
  "IQ-25": {
    short: "RMCO - Rilievi di Manutenzione Correttiva",
    long: "L’indicatore conteggia le non conformità rilevate dall’Amministrazione per obbligazioni contrattuali non adempiute nei tempi e nei modi previsti, siano esse rilevate da specifici indicatori o non conformità, non presidiati da specifici indicatori. Si precisa che qualsiasi intervento – ivi compresa la manutenzione correttiva – non deve peggiorare il debito tecnico della funzione/codice modificato né introdurre non conformità e/o violazioni. Ogni elemento negativo sulla qualità del software deve essere rimosso in garanzia ed alimenta il presente indicatore di rilievi.",
  },
  "IQ-26": {
    short: "DSGP - Disponibilità dei servizi di gestione del portafoglio applicativo",
    long: "L’indicatore misura la disponibilità del servizio secondo i tempi ed i modi definiti nel Contratto Esecutivo, nel Piano di lavoro Generale e Piano di lavoro del servizio, ivi incluse le estensioni di orario di servizio, la reperibilità e l’extra-orario.",
  },
  "IQ-27": {
    short: "RSCA - Rispetto di una scadenza dei servizi di gestione del Portafoglio",
    long: "L’indicatore misura il rispetto di scadenze temporali concordate o pianificate nei piani di gestione del portafoglio, ivi incluse le estensioni di orario di servizio, la reperibilità e l’extra-orario.",
  },
  "IQ-28": {
    short: "TRRA - Tempestività di risoluzione delle richieste di assistenza",
    long: "L’indicatore misura l’efficacia della prestazione mediante la percentuale di chiamate risolte o, dopo l’identificazione della causa, inoltrate al gruppo responsabile della risoluzione (es. manutenzione correttiva).",
  },
  "IQ-29": {
    short: "RSGT - Rilievi sui servizi di gestione del Portafoglio Applicativo",
    long: "L’indicatore conteggia le non conformità rilevate dall’Amministrazione per obbligazioni contrattuali non adempiute nei tempi e nei modi previsti, siano esse rilevate da specifici indicatori o non conformità, non presidiati da specifici indicatori.",
  },
  "IQ-30": {
    short: "SPSS - Slittamento nella consegna di un'attività del servizio di Supporto Specialistico",
    long: "L’indicatore misura il rispetto della data di consegna di un’attività concordata/pianificata",
  },
  "IQ-31": {
    short: "CSIS - Customer effort Score dell'intervento specialistico",
    long: "Il presente indicatore misura l’esperienza d’uso dei servizi da parte dell’Amministrazione, rilevata con l’impiego di strumenti messi a disposizione dal Fornitore per l’acquisizione dei feedback degli utenti secondo quanto specificato al par. 8 dei CTS.",
  },
  "IQ-32": {
    short: "RSSP - Rilievi sui servizi di supporto specialistico",
    long: "L’indicatore conteggia le non conformità rilevate dall’Amministrazione per obbligazioni contrattuali non adempiute nei tempi e nei modi previsti, siano esse rilevate da specifici indicatori o non conformità, non presidiati da specifici indicatori.",
  },
  "IQ-33": {
    short: "RSCC - Rispetto di una scadenza dei servizi di sviluppo e evoluzione sw in co-working",
    long: "L’indicatore misura il rispetto di scadenze temporali concordate o pianificate nei piani di gestione sviluppo e evoluzione sw in co-working, ivi inclusi le estensioni di orario di servizio, la reperibilità e l’extra-orario.",
  },
  "IQ-34": {
    short: "DSA - Disponibilità dei sistemi e apparati",
    long: "L’indicatore misura il livello di servizio “Disponibilità di un sistema DSA_xxx”, dove xxx è un progressivo che identifica il singolo Sistema.",
  },
  "IQ-35": {
    short: "EASA - Esecuzione delle attività su sistemi e apparati",
    long: "L’indicatore misura misura la tempestività nell’esecuzione delle attività ove possibili programmate (es. batch, backup, ecc…), richieste dall’utenza di riferimento (es. gestione utenze/password, allineamento basi dati, restore, ecc…) e/o derivanti dall’attività proattiva del fornitore (es. creazione Pgm/Shell, passaggio di una patch di sistema, ecc..) da parte del servizio di Conduzione tecnica.",
  },
  "IQ-36": {
    short: "TROSA - Tempestività nel ripristino dell'operatività dei sistemi e apparati",
    long: "L’indicatore misura la tempestività nella risoluzione degli incident relativi agli apparati e sistemi gestiti.",
  },
};