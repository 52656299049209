import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Tag } from "@/models";
import { SD_BASE_API_URL } from "@/utils/Constants";
import axios from "axios";
import { httpStore } from "@/store/typed";
import { TagMixinTags } from "@/mixins/http/TagsMixin";

@Module({
  stateFactory: true,
  namespaced: true,
  name: "tags",
})
export default class Tags extends VuexModule {
  private _tags: Tag[] = [];

  get tags(): Tag[] {
    return this._tags;
  }

  @Mutation
  setTags(tags: Tag[]) {
    this._tags = tags;
  }

  @Action
  async refreshTags() {
    const { commit } = this.context;

    try {
      const url = `${SD_BASE_API_URL}/tags`;

      const tags = (await httpStore.request({
        tag: TagMixinTags.TagIndex,
        url: url,
        method: "GET",
      })) as Tag[];
      commit("setTags", tags);
    } catch (e) {
      commit("setTags", []);
    }
  }

  @Action
  async loadTags() {
    if (this._tags.length === 0) {
      await this.refreshTags();
    }
  }
}
