
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class PageSection extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) readonly subtitle!: string;
  @Prop({ default: true }) readonly withPadding!: boolean;
  @Prop({ default: "lg" }) readonly titleSize!: "sm" | "md" | "lg" | "xs";
  @Prop() readonly icon?: string;
  @Prop({ default: false }) readonly backLink!: boolean;
  @Prop({ default: false }) readonly backTo!: string;
  @Prop({ default: true }) readonly actionAlwaysOnSide!: boolean;

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  back() {
    if (this.backTo) {
      this.$router.push({ name: this.backTo });
    } else {
      this.$router.back();
    }
  }

  get previousRoute() {
    return this.backTo || -1;
  }

  get titleClass() {
    return this.isMobile
      ? "section-title section-title--xs"
      : `section-title section-title--${this.titleSize}`;
  }
}
