import { Document, User } from "@/models";
import { DocumentStatus } from "../Document";

export default class RequirementsPlan extends Document {
  static type = "documentRequirementsPlans";

  static statuses: DocumentStatus = [...Document.statuses];

  statusLabel(user: User) {
    const status = RequirementsPlan.statuses.find((o) => o.id === this.status);
    return status?.labels[user.role] || status?.labels.default;
  }

  get statusClass() {
    const status = RequirementsPlan.statuses.find((o) => o.id === this.status);
    return status?.class;
  }

  get initiativeStatus() {
    const withoutSuperClass = RequirementsPlan.type.replace("document", "");
    return (
      withoutSuperClass.charAt(0).toLocaleLowerCase() +
      withoutSuperClass.slice(1)
    );
  }
}
