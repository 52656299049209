import Company from "./Company";
import Model from "./Model";
import RealmCompany from "./RealmCompany";

export default class Realm extends Model {
  static type = "realms";

  name!: string;
  code!: string;
  representative!: Company;
  companyRealms!: RealmCompany[];
}
