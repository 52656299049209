import styles from "@/styles/shared/_variables_overrides.scss";
export type Typography = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "base";

export function propertyByName(colorName: string) {
  return styles[colorName];
}

export function primaryColor() {
  return propertyByName("primary");
}

export function secondaryColor() {
  return propertyByName("secondary");
}

export function fontSize(fontType: Typography) {
  return propertyByName(`${fontType.toString()}-font-size`);
}

export function numericalPart(value: string) {
  return parseFloat(value.replace(/^\D|,+/g, ""));
}
