
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class VSvg extends Vue {
  @Prop() readonly code!: string;

  get svgIcon() {
    return `${require("@/assets/icons/sprite.svg")}#${this.code}`;
  }
}
