import { getModule } from "vuex-module-decorators";
import store from "@/store";
import Http from "@/store/http";
import CurrentUser from "@/store/currentUser";
import Auth from "./auth";
import Zone from "./zone";
import Informative from "./informative";
import Watcher from "./watcher";
import Configurator from "./configurator";
import Monitoring from "@/store/monitoring";
import Print from "@/store/print";
import Tags from "@/store/tags";
export const informativeStore: Informative = getModule(Informative, store);
export const currentUserStore: CurrentUser = getModule(CurrentUser, store);
export const httpStore: Http = getModule(Http, store);
export const authStore: Auth = getModule(Auth, store);
export const zoneStore: Zone = getModule(Zone, store);
export const configuratorStore: Configurator = getModule(Configurator, store);
export const watcherStore: Watcher = getModule(Watcher, store);
export const monitoringStore: Monitoring = getModule(Monitoring, store);
export const printStore: Print = getModule(Print, store);
export const tagsStore: Tags = getModule(Tags, store);
