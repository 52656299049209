import { Model } from "@/models";
import Organization from "./Organization";

export class InitiativeOrganizationPayload {
  organizationId!: string;
  initiativeId!: string;
}

export default class InitiativeOrganization extends Model {
  static type = "initiativeOrganizations";

  organization!: Organization;
  initiativeId!: string;

  get organizationName() {
    return this.organization?.name;
  }
}
