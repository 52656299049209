import { render, staticRenderFns } from "./SlimNavbar.vue?vue&type=template&id=0381229c&scoped=true"
import script from "./SlimNavbar.vue?vue&type=script&lang=ts"
export * from "./SlimNavbar.vue?vue&type=script&lang=ts"
import style0 from "./SlimNavbar.vue?vue&type=style&index=0&id=0381229c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0381229c",
  null
  
)

export default component.exports