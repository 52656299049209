import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Styleguide from "@/views/Styleguide.vue";
import { authStore } from "@/store/typed";
import { User } from "@/models";
import SecureRoutes from "@/router/Secure";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    meta: { noAuth: true },

    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    meta: { role: ["anyone"] },
    path: "/secure",
    name: "secure_root",
    component: () =>
      import(/* webpackChunkName: "secure" */ "../views/Secured.vue"),
    children: [...SecureRoutes],
  },
  {
    path: "/pages/:page",
    name: "dynamic_page",
    meta: { noAuth: true },
    component: () => import(/* webpackChunkName: "page" */ "../views/Page.vue"),
  },
  {
    path: "/news/:id",
    name: "news_show",
    meta: { noAuth: true },
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/News/Show.vue"),
  },
  {
    path: "/news",
    name: "news_list",
    meta: { noAuth: true },
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/News/List.vue"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    meta: { noAuth: true },
    component: () =>
      import(/* webpackChunkName: "contact-us" */ "../views/Contact.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { noAuth: true },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Registration.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { noAuth: true },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Login.vue"),
  },
  {
    path: "/styleguide",
    name: "styleguide",
    meta: { noAuth: true, role: ["anyone"] },
    component: () =>
      import(/* webpackChunkName: "styleguide" */ "../views/Styleguide.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    meta: { noAuth: true },
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ.vue"),
  },

  {
    path: "/not_found",
    name: "not_found",
    meta: { noAuth: true },
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/NotFound.vue"),
  },

  {
    path: "/confirmation",
    name: "confirmation",
    meta: { noAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/ConfirmWithPassword.vue"
      ),
  },
  {
    path: "/chi-siamo",
    name: "chi-siamo",
    meta: { noAuth: true },
    component: () => import(/* webpackChunkName: "faq" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const { role, noAuth } = to?.matched[0]?.meta || {};
  authStore.authenticate().then(
    (user: User | undefined) => {
      if (to?.matched?.some((record) => record.meta.noAuth)) {
        next();
        return;
      }

      if (role?.includes(user?.role) || role?.includes("anyone")) {
        next();
        return;
      } else {
        // if not it is redirected
        next(false);
        return;
      }
    },
    (err) => {
      if (to?.matched?.every((record) => record.meta.noAuth)) {
        next();
        return;
      } else {
        next({ name: "login" });
      }
    }
  );
});

export default router;
