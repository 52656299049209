
import { Vue, Component, Prop } from "vue-property-decorator";
import { currentUserStore, authStore } from "@/store/typed";

import ClickOutside from "@/directives/ClickOutside";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class LogoutDropdown extends Vue {
  @Prop({ default: false }) readonly isMobile!: boolean;

  private open = false;

  get logged() {
    return currentUserStore.currentUser;
  }

  get isAdmin() {
    return this.logged.isAdmin || this.logged.isSuperAdmin;
  }

  logout() {
    authStore.logout();
    this.$router.push({ name: "login" });
  }

  toggleOpen() {
    this.open = !this.open;
  }

  get securedHome() {
    return this.isOccUser ? "watcher_area_home" : "info_home";
  }

  get isOccUser() {
    return currentUserStore.currentUser.isOccUser;
  }

  closeDropdown() {
    this.open = false;
  }
}
