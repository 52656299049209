import customScroller from "@/initializers/ScrollToUtil";
import { Model } from "@/models";
import { QuantitativeEntry } from "@/models/IndicatorQuantitative";
import { MapDataEntry } from "./echart/types";

export const IndicatorDeltaPositivity: {
  code: string;
  positity: "positive" | "negative";
}[] = [
  { code: "IG1", positity: "negative" },
  { code: "IG2", positity: "negative" },
  { code: "IG3", positity: "positive" },
];

export default class QuantitativeIndicatorReport {
  code!: string;
  name!: string;
  data!: QuantitativeEntry | any;
  measurementType!: "money" | "time" | "quantity";
  realmName!: string;

  static dummy(code?: string, name?: string) {
    const instance = new QuantitativeIndicatorReport();
    instance.code = code || "IG.1";
    instance.name = name || "Riduzione % spesa per l'erogazione del servizio";
    instance.measurementType = "money";
    instance.data = [
      {
        region: "Puglia",
        budget: { saved: 2, spent: 8 },
        expense: { before: 15, after: 20 },
        projects: { completed: 15, inProgress: 9 },
      },
      {
        region: "Sicilia",
        budget: { saved: 2, spent: 8 },
        expense: { before: 20, after: 15 },
        projects: { completed: 16, inProgress: 9 },
      },
      {
        region: "Abruzzo",
        budget: { saved: 2, spent: 8 },
        expense: { before: 20, after: 15 },
        projects: { completed: 13, inProgress: 9 },
      },
      {
        region: "Sardegna",
        budget: { saved: 2, spent: 8 },
        expense: { before: 20, after: 15 },
        projects: { completed: 12, inProgress: 9 },
      },
      {
        region: "Campania",
        budget: { saved: 2, spent: 8 },
        expense: { before: 20, after: 15 },
        projects: { completed: 18, inProgress: 9 },
      },
      {
        region: "Molise",
        budget: { saved: 2, spent: 8 },
        expense: { before: 20, after: 15 },
        projects: { completed: 18, inProgress: 9 },
      },
      {
        region: "Basilicata",
        budget: { saved: 2, spent: 8 },
        expense: { before: 20, after: 15 },
        projects: { completed: 18, inProgress: 9 },
      },
      {
        region: "Calabria",
        budget: { saved: 2, spent: 8 },
        expense: { before: 20, after: 15 },
        projects: { completed: 18, inProgress: 9 },
      },
    ];

    return instance;
  }

  completedProjectsByRegion(region: string) {
    return this.regionValue(region)?.projects?.completed || 0;
  }

  inProgressProjectsByRegion(region: string) {
    return this.regionValue(region)?.projects?.inProgress || 0;
  }

  allProjectsByRegion(region: string) {
    return (
      this.inProgressProjectsByRegion(region) +
      this.completedProjectsByRegion(region)
    );
  }

  budgetSpentByRegion(region: string) {
    return this.regionValue(region).budget.spent;
  }

  budgetSavedByRegion(region: string) {
    return this.regionValue(region).budget.saved;
  }

  budgetTotalByRegion(region: string) {
    return this.budgetSpentByRegion(region) + this.budgetSavedByRegion(region);
  }

  expensesBefore(region: string) {
    return this.regionValue(region).expense.before;
  }

  expensesAfter(region: string) {
    return this.regionValue(region).expense.after;
  }

  regionValue(region: string): any {
    return this.data.find((o: any) => o.region === region);
  }

  get totalProjects() {
    return this.data.reduce((a: any, b: any) => {
      return (
        parseInt(a) +
        parseInt(b.projects.completed) +
        parseInt(b.projects.inProgress)
      );
    }, 0);
  }

  get totalBudget() {
    return this.spentBudget + this.savedBudget;
  }

  get spentBudget() {
    return this.data.reduce((a: any, b: any) => {
      return parseInt(a) + parseInt(b.budget.saved);
    }, 0);
  }

  get savedBudget() {
    return this.data.reduce((a: any, b: any) => {
      return parseInt(a) + parseInt(b.budget.spent);
    }, 0);
  }

  get totalExpenseBefore() {
    return this.data.reduce((a: any, b: any) => {
      return parseInt(a) + parseInt(b.expense.before);
    }, 0);
  }

  get totalExpenseAfter() {
    return this.data.reduce((a: any, b: any) => {
      return parseInt(a) + parseInt(b.expense.after);
    }, 0);
  }

  get completedProjects() {
    return this.data.reduce((a: any, b: any) => {
      return parseInt(a) + parseInt(b.projects.completed);
    }, 0);
  }
  get inProgressProjects() {
    return this.totalProjects - this.completedProjects;
  }

  get delta(): number {
    const expensesAfter = this.totalExpenseAfter;
    const expensesBefore = this.totalExpenseBefore;
    if (!expensesAfter && !expensesBefore) {
      return 0;
    }

    const difference = expensesAfter - expensesBefore;
    const value = (difference / expensesBefore) * 100 || 0;

    return parseFloat(value.toFixed(2));
  }

  get absoluteDelta(): string {
    return Math.abs(this.delta).toFixed(2);
  }

  get toMapValues() {
    return this.data.map((o: MapDataEntry) => {
      let value;

      if (o.expense.after > o.expense.before) {
        value = (o.expense.before / o.expense.after) * 100 - 100;
      } else {
        value = 100 - (o.expense.after / o.expense.before) * 100;
      }

      return { name: o.region, value: value?.toFixed(2) || 0 };
    });
  }
}
