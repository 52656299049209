
import { Vue, Component, Prop } from "vue-property-decorator";
import NavbarItem from "../../models/ui/NavbarItem";
import customScroll from "@/initializers/ScrollToUtil";
import { currentUserStore } from "@/store/typed";

@Component({})
export default class StickyNavbar extends Vue {
  @Prop() readonly rightLinks!: NavbarItem[];
  @Prop() readonly leftLinks!: NavbarItem[];

  get currentUser() {
    return currentUserStore.currentUser;
  }
  get isLogged() {
    return !!this.currentUser;
  }

  get isHome() {
    return this.$isRouteActive("home");
  }

  get isAdministrative() {
    return !this.currentUser?.isOneOf(["admin", "superadmin"]);
  }

  goOnSection(section: string) {
    customScroll.scroll(section);
  }

  routeActive(item: NavbarItem) {
    return (
      item.route === this.$route.name ||
      item.activeRoutes?.some((r: string) => r === this.$route.name)
    );
  }
}
