import { scroller } from "vue-scrollto/src/scrollTo";

import { propertyByName, numericalPart } from "@/utils/ScssHelper";
import Vue from "vue";

const DEFAULT_OFFSET = 80;

class CustomScroller extends Vue {
  scroll(section: string) {
    let offset =
      this.$mq === "sm" || this.$mq === "md"
        ? numericalPart(propertyByName("body-margin-top"))
        : numericalPart(propertyByName("body-margin-top-sm"));

    if (!offset) {
      offset = DEFAULT_OFFSET;
    }

    const options = { offset: -offset };

    if (section) {
      scroller()(section, options);
    }
  }
}

const customScroller = new CustomScroller();
export default customScroller;
