import { Model } from "@/models";
import Realm from "./Realm";

export class CompanyPayload {
  name: string;
  logo: any;
  logoUrl: string;
  id: string;

  constructor(company: Company) {
    this.name = company.name;
    this.logo = null;
    this.logoUrl = company.logoUrl;
    this.id = company.id;
  }

  static empty(): CompanyPayload {
    return {
      id: null,
      name: null,
      logo: null,
      logoUrl: null,
    };
  }
}

export default class Company extends Model {
  static type = "companies";

  name!: string;
  logoUrl!: string;
  realms: Realm[];
}
