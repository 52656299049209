import { booleanStringToBooleanPrimitive } from "./EnvUtils";
import { IG_DESCRIPTIONS } from "./indicatorsDescriptions/IG";
import { IM_DESCRIPTIONS } from "./indicatorsDescriptions/IM";
import { IQ_DESCRIPTIONS } from "./indicatorsDescriptions/IQ";
import { IS_DESCRIPTIONS } from "./indicatorsDescriptions/IS";

export const STRAPI_BASE_API_URL: string =
  process.env.STRAPI_BASE_API_URL ??
  "https://sd-gateway.staging.monade.io/content";

export const IS_EDITION_3: boolean = booleanStringToBooleanPrimitive(process.env.IS_EDITION_3);

export const STRAPI_MEDIA_BASE_API_URL: string =
  process.env.STRAPI_MEDIA_BASE_API_URL ??
  "https://sd-gateway.staging.monade.io/content";

export const SD_BASE_API_URL: string =
  process.env.SD_BASE_API_URL ?? "http://sd-api.test/v1";

export const SD_BASE_ADMIN_API_URL: string =
  `${SD_BASE_API_URL}/admin` ?? "http://sd-api.test/v1/admin";

export const FRONTEND_URL: string =
  process.env.FRONTEND_URL ?? "http://sd.test";

export const DEFAULT_LOGGED_ROUTE = "secure/orders?page=1";

export const IPA_BASE_API_URL = "https://indicepa.gov.it/PortaleServices/api/";

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

const northRegions = [
  "Liguria",
  "Lombardia",
  "Piemonte",
  "Valle d'Aosta",
  "Emilia-Romagna",
  "Friuli-Venezia Giulia",
  "Trentino-Alto Adige",
  "Veneto"
]

const centerSouthRegions = [
  "Lazio",
  "Marche",
  "Toscana",
  "Umbria",
  "Sardegna",
  "Sicilia",
  "Abruzzo",
  "Basilicata",
  "Calabria",
  "Campania",
  "Molise",
  "Puglia",
]

export const REALM_REGIONS: any = {
  north: northRegions,
  center: centerSouthRegions,
  'ppa-north': northRegions,
  'ppa-center': centerSouthRegions
};

export const CHART_PALETTE: string[] = [
  "#D0E2F5",
  "#A6CAED",
  "#7FB2E5",
  "#599BDE",
  "#3686D6",
  "#0059B3",
  "#004D99",
  "#004080",
  "#003366",
  "#00264D",
  "#001A33",
];

export const CHART_PRIMARY_COLOR = "#0059B3";
export const CHART_SECONDARY_COLOR = "#5C6F82";

export const CHART_S1_COLOR = "#0059B3";
export const CHART_S2_COLOR = "#5C6F82";
export const CHART_S3_COLOR = "#00cf86";
export const CHART_S4_COLOR = "#6b65e0";

export const INDICATOR_DESCRIPTIONS: Record<
  string,
  {
    short: string;
    long: string;
    familyDescription?: string;
    publicTender?: string;
  }
> = { ...IM_DESCRIPTIONS, ...IG_DESCRIPTIONS, ...IQ_DESCRIPTIONS, ...IS_DESCRIPTIONS };
