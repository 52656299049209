
import { Vue, Component, Prop } from "vue-property-decorator";
import UserNavbarDropdown from "@/components/Common/UserNavbarDropdown.vue";
import { currentUserStore } from "@/store/typed";
import NavbarItem from "../../models/ui/NavbarItem";

@Component({ components: { UserNavbarDropdown } })
export default class SlimNavbar extends Vue {
  @Prop() readonly links!: NavbarItem[];

  get logged() {
    return currentUserStore.currentUser;
  }
}
