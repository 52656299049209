import { Company, Model, User } from "@/models";

export default class RealmCompany extends Model {
  static type = "companyRealms";

  company!: Company;
  realmId!: string;

  get companyName() {
    return this.company?.name;
  }
}
