import { Model } from "..";
import Company from "./Company";
import DashboardItem from "./DashboardItem";
import Media from "./Media";
import SocialIcon from "./SocialLink";
import Faq from "@/models/strapi/Faq";
import ContactData from "./ContactData";
import MetaTag from "@/models/strapi/MetaTag";
import FaqDomain from "./FaqDomain";

export default class Zone extends Model {
  aq_summary!: string;
  aq_text!: string;
  badge_text!: string;
  playlist_url!: string;
  companies!: Company[];
  aq_documents!: Document[];
  dashboard_items!: DashboardItem[];
  dashboard_text!: string;
  carousel_images!: Media[];
  page_title!: string;
  page_subtitle!: string;
  sub_header_text!: string;
  header_text!: string;
  social_icons!: SocialIcon[];
  privacy_page!: string;
  cookie_page!: string;
  faq!: Faq[];
  contact_data!: ContactData[];
  meta_tags!: MetaTag[];

  constructor() {
    super();
  }
}
