import { Model } from "..";
import MediaFormat from "./MediaFormat";
import { STRAPI_BASE_API_URL } from "@/utils/Constants";

export type MediaSize = "large" | "medium" | "thumbnail" | "small";

export default class Media extends Model {
  alternative_text!: string;
  caption!: string;
  formats!: Record<string, MediaFormat>;
  url!: string;

  /**
   * @deprecated
   * @param size
   */
  builtUrl(size: MediaSize) {
    //TODO: unused? remove?
    const url = this.formats
      ? `${STRAPI_BASE_API_URL}/${this.formats[size].url}`
      : `${STRAPI_BASE_API_URL}/${this.url}`;
    return url;
  }
}
