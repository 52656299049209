import { Model } from "@/models";

export default class ExecutiveContractTrendReport extends Model {
  code!: string;
  category!: string;
  name!: string;
  data!: number;
  suffix!: string;
  max!: number;

  static dummy(category?: string, code?: string, name?: string) {
    const instance = new ExecutiveContractTrendReport();

    instance.category = category || "IM";
    instance.code = code || "5";
    instance.name = name || "Lorem Ipsum";
    instance.data = 35;
    instance.max = 100;
    return instance;
  }
}
