import { Model, User } from "@/models";
import Service from "./strapi/Service";
import Task from "./Task";

export default class ContractService extends Model {
  static type = "contractServices";

  contractId!: string;
  serviceId!: string;
  assigneeId!: string;
  projectTitle!: string;
  startsAt!: Date;
  endsAt!: Date;
  days!: number;
  duration!: number;
  value!: number;
  service!: Service;
  assignee!: User;
  progress!: number;
  billingType!: string;
  executionPlace!: string;
  tasks: Task[];

  get isMeasuredPrice() {
    return this.billingType === "measured_price";
  }

  get isFixedPrice() {
    return this.billingType === "fixed_price";
  }
}
