var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"it-header-navbar-wrapper primary-bg-a12"},[_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 px-0"},[_c('nav',{staticClass:"navbar navbar-expand-lg has-megamenu"},[_c('div',{staticClass:"navbar-collapsable",staticStyle:{"display":"block"},attrs:{"id":"nav02"}},[_vm._m(0),_c('div',{staticClass:"menu-wrapper"},[_c('ul',{staticClass:"navbar-nav",class:{ 'carded shadow': _vm.isLogged && !_vm.isHome }},[_vm._l((_vm.leftLinks),function(link,index){return [(!link.hidden)?_c('li',{key:index,staticClass:"nav-item",class:{
                      'left-separator': index !== 0 && _vm.isLogged && !_vm.isHome,
                    }},[_c('router-link',{staticClass:"nav-link",class:{
                        'pl-0': index == 0 && (!_vm.isLogged || _vm.isHome),
                        'router-link-exact-active': _vm.routeActive(link),
                      },attrs:{"to":link.route},nativeOn:{"click":function($event){return _vm.goOnSection(link.section)}}},[_c('span',[_vm._v(_vm._s(link.label))])])],1):_vm._e()]})],2),_c('div',{staticClass:"it-right-zone"},[_c('ul',{staticClass:"navbar-nav"},_vm._l((_vm.rightLinks),function(link,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link pr-0",attrs:{"to":link.route}},[(link.icon)?_c('v-icon',{staticClass:"text-white mr-1",attrs:{"size":"xs"}},[_vm._v(_vm._s(link.icon))]):_vm._e(),_c('span',[_vm._v(_vm._s(link.label))])],1)],1)}),0)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"close-div sr-only"},[_c('button',{staticClass:"btn close-menu",attrs:{"type":"button"}},[_c('span',{staticClass:"it-close"}),_vm._v("close ")])])
}]

export { render, staticRenderFns }