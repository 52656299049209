export default class MonitoringAreaData {
  executiveContractsTrend!: any;
  monitoringSupplyIndicators!: any;
  qualitativeIndicators!: any;
  reviewIndicators!: any;

  constructor(contracts?: any, supply?: any, qualitative?: any, review?: any) {
    this.executiveContractsTrend = contracts || null;
    this.monitoringSupplyIndicators = supply || null;
    this.qualitativeIndicators = qualitative || null;
    this.reviewIndicators = review || null;
  }
}
