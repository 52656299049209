import OrganizationMixin from "@/mixins/http/OrganizationMixin";
import { InitiativeOrganization, Model, Organization } from "@/models";
import Contract from "./Contract";
import ExecutiveContract from "./document/ExecutiveContract";
import OperativePlan from "./document/OperativePlan";
import RequirementsPlan from "./document/RequirementsPlan";
import WorkPlan from "./document/WorkPlan";
import Document from "@/models/Document";
import InitiativeMetadata from "./InitiativeMetadata";
import Milestone from "./Milestone";
import InitiativeTag from "@/models/InitiativeTag";

export type InitiativeStep = Array<{
  id: string;
  class: string;
  label: string;
}>;

export default class Initiative extends Model {
  static steps: InitiativeStep = [
    { id: "negotiation", class: "primary", label: "In attivazione" },
    { id: "completed", class: "secondary", label: "Conclusa" },
    { id: "not_started", class: "danger", label: "Non avviata" },
    { id: "in_progress", class: "success", label: "In esecuzione" },
  ];

  get stepClass() {
    const status = Initiative.steps.find((o) => o.id === this.step);
    return status?.class;
  }

  get stepLabel() {
    const status = Initiative.steps.find((o) => o.id === this.step);
    return status?.label;
  }

  static type = "initiatives";
  name!: string;
  code!: string;
  step!: string;
  status!: string;
  organization!: Organization;
  executiveContracts!: ExecutiveContract[];
  workPlans!: WorkPlan[];
  requirementsPlans!: RequirementsPlan[];
  operativePlans!: OperativePlan[];
  contract!: Contract;
  milestones!: Milestone[];
  lastUploadedDocument!: Document;
  contactPerson!: string;
  publishable!: boolean;
  alternativeTitle!: string;
  alternativeDescription!: string;
  initiativeOrganizations!: InitiativeOrganization[];
  initiativeTags!: InitiativeTag[];

  get workPlansStep() {
    return this.status === "work_plans";
  }

  get requirementsPlansStep() {
    return this.status === "requirements_plans";
  }

  get executiveContractsStep() {
    return this.status === "executive_contracts";
  }

  get lat() {
    return this.organization.lat;
  }

  get lon() {
    return this.organization.lon;
  }

  get operativePlansStep() {
    return this.status === "operative_plans";
  }

  get createdStep() {
    return this.status === "created";
  }

  get isClosed() {
    return this.status === "closed";
  }

  metadata!: InitiativeMetadata;
}
