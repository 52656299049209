export const IM_DESCRIPTIONS: Record<
  string,
  {
    short: string;
    long: string;
    familyDescription?: string;
    publicTender?: string;
  }
> = {
  "IM-1": {
    short: "",
    long: "Servizi di PMO",
    familyDescription:
      "Numerosità / Distribuzione dei Servizi di PMO nei contratti esecutivi attivati",
  },
  "IM-1-1": {
    short: "Servizio S1",
    long: "Servizio di Sviluppo",
  },
  "IM-1-2": {
    short: "Servizio S2",
    long: "Servizi di Manutenzione Software"
  },
  "IM-1-3": {
    short: "Servizio S3",
    long: "Servizi di Conduzione Applicativa",
  },
  "IM-1-4": {
    short: "Servizio S4",
    long: "Servizi Infrastrutturali",
  },

  "IM-2": {
    short: "",
    long: "Composizione progetti",
    familyDescription:
      "Informazione sul contenuto dei progetti attualmente in corso",
  },
  "IM-2-S1": {
    short: "Servizio S1",
    long: "Servizio di Sviluppo",
  },
  "IM-2-S2": {
    short: "Servizio S2",
    long: "Servizi di Manutenzione Software"
  },
  "IM-2-S3": {
    short: "Servizio S3",
    long: "Servizi di Conduzione Applicativa",
  },
  "IM-2-S4": {
    short: "Servizio S4",
    long: "Servizi Infrastrutturali",
  },

  "IM.2_Servizio-S1.1": {
    short: "IM.2_Servizio-S1.1",
    long: "Servizio di Sviluppo di Applicazioni Software Ex-novo Green Field (GF)",
  },
  "IM.2_Servizio-S1.2": {
    short: "IM.2_Servizio-S1.2",
    long: "Servizio di Manutenzione Evolutiva di Applicazioni Esistenti (MEV)",
  },
  "IM.2_Servizio-S1.3": {
    short: "IM.2_Servizio-S1.3",
    long: "Migrazione_Applicativa_al_Cloud_(MC)",
  },
  "IM.2_Servizio-S1.4": {
    short: "IM.2_Servizio-S1.4",
    long: "Servizio di Parametrizzazione e Personalizzazione di Soluzioni di terze parti/open source/riuso (PP)",
  },
  "IM.2_Servizio-S1.5": {
    short: "IM.2_Servizio-S1.5",
    long: "Servizio di sviluppo e evoluzione sw in co-working con l’Amministrazione (COW)",
  },

  "IM.2_Servizio-S2.1": {
    short: "IM.2_Servizio-S2.1",
    long: "MAD Manutenzione Adeguativa",
  },
  "IM.2_Servizio-S2.2": {
    short: "IM.2_Servizio-S2.2",
    long: "MAC Manutenzione Correttiva sul Software ad hoc “pregresso e non in garanzia”",
  },

  "IM.2_Servizio-S3.1": {
    short: "IM.2_Servizio-S3.1",
    long: "Gestione applicativi e base di dati",
  },
  "IM.2_Servizio-S3.2": {
    short: "IM.2_Servizio-S3.2",
    long: "Gestione del Front-End Digitale Publishing e contenuti di Siti Web",
  },
  "IM.2_Servizio-S3.3": {
    short: "IM.2_Servizio-S3.3",
    long: "Supporto Specialistico",
  },

  "IM.2_Servizio-S4.1": {
    short: "IM.2_Servizio-S4.1",
    long: "Servizio di Conduzione Tecnica (CT)",
  },
  "IM.2_Servizio-S4.2": {
    short: "IM.2_Servizio-S4.2",
    long: "Supporto tecnologico (ST)",
  },

  "IM-3": {
    short: "",
    long: "Ambiti di intervento",
    familyDescription:
      "Numerosità/Distribuzione degli ambiti di intervento nei contratti esecutivi attivati",
  },
  "IM-3-1": { short: "ACCESSO AI SERVIZI", long: "Accesso ai servizi" },
  "IM-3-2": { short: "ECOSISTEMI", long: "Ecosistemi" },
  "IM-3-3": { short: "PIATTAFORME", long: "Piattaforme" },
  "IM-3-4": { short: "DATI DELLA PA", long: "Dati della PA" },
  "IM-3-5": {
    short: "MODELLO DI INTEROPERABILITA",
    long: "Modello di interoperabilità",
  },
  "IM-3-6": { short: "INFRASTRUTTURE", long: "Infrastrutture" },
  "IM-3-7": { short: "SICUREZZA", long: "Sicurezza" },
  "IM-3-8": {
    short: "MODELLI E STRUMENTI PER L'INNOVAZIONE",
    long: "Strumenti per l'innovazione",
  },
  "IM-3-9": {
    short: "GESTIONE DEL TRANSIENTE",
    long: "Gestione del transiente",
  },

  "IM-4": {
    short: "",
    long: "Indicatori generali di digitalizzazione (IG)",
    familyDescription:
      "Numerosità/Distribuzione degli indicatori generali di digitalizzazione nei contratti esecutivi attivati",
  },
  "IM-4-1": {
    short: "IG 1",
    long: "Riduzione % della spesa per l’erogazione del servizio",
  },
  "IM-4-2": {
    short: "IG 2",
    long: "Riduzione % dei tempi di erogazione del servizio",
  },
  "IM-4-3": {
    short: "IG 3",
    long: "Numero servizi aggiuntivi offerti all’utenza interna, esterna cittadini, esterna impresa, altra PA",
  },
  "IM-4-4": {
    short: "IG 4",
    long: "Obiettivi CAD raggiunti con l’intervento ",
  },
  "IM-4-5": { short: "IG 5", long: "Infrastrutture immateriali integrate " },
  "IM-4-6": {
    short: "IG 6",
    long: "Integrazione con Basi Dati di interesse nazionale",
  },
  "IM-4-7": {
    short: "IG 7",
    long: "Riuso di processi per erogazione servizi digitali",
  },
  "IM-4-8": { short: "IG 8", long: "Riuso soluzioni tecniche" },
  "IM-4-9": {
    short: "IG 9",
    long: "Collaborazione con altre Amministrazioni (progetto in comune a più Amministrazioni)",
  },

  "IM-5": {
    short: "Giornate erogate",
    long: "Giornate erogate sul totale di giornate previste dai contratti esecutivi attivati ",
  },
  "IM-6": {
    short: "Capienza economica residua",
    long: "Capienza economica residua rispeto al budget totale dei contratti esecutivi attivati",
  },
};