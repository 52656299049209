
import { Vue, Component } from "vue-property-decorator";
import { currentUserStore, zoneStore } from "../../store/typed";
import SocialLinks from "@/components/Common/SocialLinks.vue";
import CompanyLogos from "@/components/Strapi/Company/Logos.vue";
@Component({ components: { SocialLinks, CompanyLogos } })
export default class TheFooter extends Vue {
  get logged() {
    return currentUserStore.currentUser;
  }

  get companies() {
    return zoneStore.zone?.companies;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get currentBreakpoint() {
    return this.$mq || "md";
  }

  dynamicPageLink(page: string) {
    return { name: "dynamic_page", params: { page: page } };
  }

  get logo() {
    return require("@/assets/icons/logo_footer.png");
  }

  goToLogin() {
    alert("TODO");
  }
}
