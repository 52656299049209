
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import VIcon from "@/components/VIcon.vue";
import VSvg from "@/components/Common/VSvg.vue";
import Faq from "@/models/strapi/Faq";
import FaqsMixin from "@/mixins/http/strapi/FaqsMixin";
import FaqDomain from "@/models/strapi/FaqDomain";
import { safeAsync } from "@/utils/AsyncUtil";
import ClickOutside from "@/directives/ClickOutside";
import { zoneStore } from "@/store/typed";
import Spinner from "@/components/Common/Spinner.vue";
import marked from "marked";
import Throttler from "@/utils/Throttler";

export enum ChatBotLevel {
  DomainLevel,
  FaqLevel,
  AnswerLevel,
}

export interface ChatMessage {
  text: string | null;
  type: "user" | "bot" | "suggestions";
}

@Component({
  components: {
    VIcon,
    VSvg,
    Spinner,
  },
  directives: {
    ClickOutside,
  },
})
export default class ChatBot extends Mixins(FaqsMixin) {
  private open = false;

  private faqDomains: FaqDomain[] = [];

  private faqsToShow: Faq[] = [];
  private currentDomain: FaqDomain | null = null;
  private currentFaq: Faq | null = null;

  private currentLevel = ChatBotLevel.DomainLevel;

  private userMessage = "";
  private messages: ChatMessage[] = [
    { type: "bot", text: "Ciao, di cosa hai bisogno?" },
  ];

  @Ref() readonly chatContainer!: HTMLElement;
  @Ref() readonly chatBotIcon!: HTMLElement;
  @Prop() readonly iconStyle!: any;

  private throttler = new Throttler();
  private loading = false;

  get domainLevel() {
    return this.currentLevel === ChatBotLevel.DomainLevel;
  }

  get faqLevel() {
    return this.currentLevel === ChatBotLevel.FaqLevel;
  }

  get answerLevel() {
    return this.currentLevel === ChatBotLevel.AnswerLevel;
  }

  get faqs() {
    return zoneStore.zone.faq;
  }

  get domains() {
    return this.faqDomains;
  }

  get markedAnswer() {
    return marked(this.currentFaq.answer);
  }

  created() {
    this.loadDomains();
  }

  private toggleOpen() {
    this.open = !this.open;

    if (!this.open) {
      this.currentLevel = ChatBotLevel.DomainLevel;
    }
  }

  private changeLevel(level: number) {
    this.currentLevel = level;

    if (level === ChatBotLevel.DomainLevel) {
      this.scrollTo(this.chatContainer.scrollHeight);
    } else if (level === ChatBotLevel.FaqLevel) {
      this.scrollTo(0);
    }
  }

  private async loadDomains() {
    const [data, errors] = await safeAsync(this.getDomains({}));

    if (data) {
      this.faqDomains = [...data];
    }
  }

  private async loadFaqs(domain: FaqDomain) {
    this.currentDomain = domain;
    this.faqsToShow = this.faqs.filter(
      (faq: Faq) => faq.faq_domain.id === domain.id
    );
    this.changeLevel(ChatBotLevel.FaqLevel);
  }

  private loadAnswer(faq: Faq) {
    this.currentFaq = faq;
    this.currentLevel = ChatBotLevel.AnswerLevel;
  }

  private sendMessage() {
    if (!this.userMessage) {
      return;
    }

    this.messages.push({ text: this.userMessage, type: "user" });

    this.loading = true;

    this.$nextTick(() => {
      this.chatContainer.scrollTop = this.chatContainer.scrollHeight;
    });

    this.throttler.run(() => {
      this.messages.push({
        text: "Ecco un elenco di argomenti che potrebbero soddisfare la tua richiesta",
        type: "bot",
      });

      this.messages.push({ text: null, type: "suggestions" });

      this.loading = false;
      this.scrollTo(this.chatContainer.scrollHeight);
      this.userMessage = "";
    });
  }

  private scrollTo(value: number) {
    this.$nextTick(() => {
      this.chatContainer.scrollTop = value;
    });
  }
}
