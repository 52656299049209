import { User } from ".";
import Model from "./Model";

export class OrganizationPayload {
  id!: string;
  name!: string;
  address!: string;
  zip!: string;
  city!: string;
  realmId: string;
  district!: string;
  pointPersonId!: string;
  region!: string;
  pec!: string;
  lat!: number;
  lon!: number;
  mail!: string;
  code!: string;
  kind!: string;
  metadata!: any;

  constructor(organization: Organization) {
    this.id = organization.id;
    this.realmId = organization.realmId?.toString();
    this.address = organization.address;
    this.name = organization.name;
    this.region = organization.region;
    this.district = organization.district;
    this.zip = organization.zip;
    this.city = organization.city;
    this.lat = organization.lat;
    this.lon = organization.lon;
    this.pec = organization.pec;
    this.mail = organization.mail;
    this.kind = organization.kind;
    this.code = organization.code;
    this.pointPersonId = organization.pointPerson?.id?.toString();
    this.metadata = organization.metadata;
  }

  fromIpaData(data: any) {
    if (data) {
      this.metadata = data;
      this.code = data.codEnte;
      this.name = data.denominazione || data.denominazioneEnte;
      this.address = data.strada;
      this.city = data.denominazioneComune;
      this.lat = data.lat;
      this.lon = data.lon;
      this.zip = data.postcode || data?.indirizzo?.tipoCapTipoCap;
      this.region = data.state;
      this.district = data.siglaAutomobilistica;
      if (data.codiceCategoria) {
        this.kind = data.codiceCategoria.startsWith("L") ? "local" : "central";
      }
      this.pec = data.mail?.mail || null;
    }
  }
}

export default class Organization extends Model {
  static kinds: Array<{ id: string; label: string }> = [
    { id: "local", label: "PAL" },
    { label: "PAC", id: "central" },
  ];

  static type = "organizations";
  name!: string;
  address!: string;
  zip!: string;
  city!: string;
  realmId: string;
  district!: string;
  pointPerson!: User;
  region!: string;
  pec!: string;
  mail!: string;
  code!: string;
  lat!: number;
  lon!: number;
  metadata!: any;
  kind!: string;
}

export type OrganizationKind = { id: string; label: string };
