import { Model } from "@/models";

export default class ExecutiveContractTrendIndicator extends Model {
  code!: string;
  category!: string;
  name!: string;
  data!: { percentage: number; absolute: number };

  static dummy(category?: string, code?: string, name?: string) {
    const instance = new ExecutiveContractTrendIndicator();
    instance.code = code || "5";
    instance.category = category || "IM";
    instance.name = name || "Lorem Ipsum";
    instance.data = {
      percentage: Math.random() * 100,
      absolute: Math.random() * 300,
    };
    return instance;
  }
}
