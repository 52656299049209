
import { Vue, Component, Prop } from "vue-property-decorator";
import { Company } from "../../../models";
import { urlFromMedia } from "@/utils/UrlHelper";
import Media from "@/models/strapi/Media";

@Component({})
export default class Logos extends Vue {
  @Prop() readonly companies!: Company[];
  @Prop({ default: "md" }) readonly size!: "md" | "lg" | "xs" | "xxl";

  url(media: Media) {
    return urlFromMedia(media);
  }
}
