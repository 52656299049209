import { Model } from "@/models";
import { DocumentMetadata, RequirementPlansMetadata } from "./DocumentMetadata";
import User from "./User";
export interface DocumentGroup {
  documents: Document[];
  label: string;
  key: string;
  active: boolean;
}

export type DocumentStatus = Array<{
  id: string;
  class: string;
  labels: Record<string, string>;
}>;

export enum DocumentType {
  RequirementsPlan = "Document::RequirementsPlan",
  OperativePlan = "Document::OperativePlan",
  ExecutiveContract = "Document::ExecutiveContract",
  WorkPlan = "Document::WorkPlan",
}

export default class Document extends Model {
  static statuses: DocumentStatus = [
    { labels: { default: "Non conforme" }, id: "rejected", class: "danger" },
    { labels: { default: "Accettato" }, id: "accepted", class: "success" },
    {
      labels: { default: "In valutazione" },
      id: "to_evaluate",
      class: "warning",
    },
  ];

  static type = "documents";

  status!: string;
  name!: string;
  version!: number;
  type!: string;
  category!: string;
  attachedAt!: Date;
  metadata!: DocumentMetadata | RequirementPlansMetadata;
  fileUrl!: string;
  documentType!: string;
  expiresIn!: number;
  rejectionMessage!: string;
  rejectionSentAt!: Date;

  statusLabel(user: User) {
    const status = Document.statuses.find((o) => o.id === this.status);
    return status?.labels[user.role] || status?.labels.default;
  }

  get statusClass() {
    const status = Document.statuses.find((o) => o.id === this.status);
    return status?.class;
  }

  static typeFromDocument(key: string): string | null {
    if (key === "operative_plans") {
      return DocumentType.OperativePlan;
    } else if (key === "requirements_plans") {
      return DocumentType.RequirementsPlan;
    } else if (key === "work_plans") {
      return DocumentType.WorkPlan;
    } else if (key === "executive_contracts") {
      return DocumentType.ExecutiveContract;
    } else {
      return null;
    }
  }

  get isToEvaluate() {
    return this.status === "to_evaluate";
  }

  get isRejected() {
    return this.status === "rejected";
  }

  get documentName() {
    if (this.documentType === DocumentType.RequirementsPlan) {
      return "Piano dei Fabbisogni";
    } else if (this.documentType === DocumentType.OperativePlan) {
      return "Piano Operativo";
    } else if (this.documentType === DocumentType.WorkPlan) {
      return "Piano dei Lavori Generale";
    } else if (this.documentType === DocumentType.ExecutiveContract) {
      return "Contratto esecutivo";
    }

    return "documento";
  }
}
