
import { Vue, Component } from "vue-property-decorator";
import Container from "@/views/Container.vue";
import { zoneStore } from "@/store/typed";
import MetaTag from "@/models/strapi/MetaTag";

import ChatBot from "@/components/Common/ChatBot.vue";

@Component({
  components: { Container, ChatBot },
  metaInfo() {
    return {
      title: this.titleTag,
      meta: this.meta,
    };
  },
})
export default class App extends Vue {
  closeToBottom = false;

  get zone() {
    return zoneStore.zone;
  }
  get metaTags(): MetaTag[] {
    return this.zone?.meta_tags;
  }

  created() {
    window.addEventListener("scroll", this.onScroll);
  }
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  }

  // get chatbotStyle() {
  //   return this.closeToBottom
  //     ? { bottom: `${this.isMobile ? 100 : 70}px` }
  //     : { bottom: "20px" };
  // }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  onScroll() {
    const distanceFromBottom =
      (this.$refs.app as any).clientHeight -
      window.scrollY -
      window.innerHeight;
    this.closeToBottom = distanceFromBottom < 100;
  }

  get meta() {
    let meta: {
      name: string;
      property: string;
      content: string;
    }[] = this.metaTags?.map((item) => {
      return {
        name: item.key,
        property: item.key,
        content: item.value,
      };
    });
    return meta;
  }

  get titleTag() {
    if (!this.zone) {
      return "Servizi Digital Transformation";
    }
    return (
      (this.zone?.header_text || "") +
      " - " +
      (this.zone?.sub_header_text || "")
    );
  }
}
