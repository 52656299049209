
import { Vue, Component, Ref } from "vue-property-decorator";
import NavbarItem from "../../models/ui/NavbarItem";
import MobileSlimNavbar from "@/components/Common/MobileSlimNavbar.vue";
import { currentUserStore, zoneStore } from "../../store/typed";
import MobileSideMenu from "@/components/Mobile/SideMenu.vue";
import UserNavbarDropdown from "@/components/Common/UserNavbarDropdown.vue";
import SlimNavbar from "@/components/Common/SlimNavbar.vue";
import StickyNavbar from "@/components/Common/StickyNavbar.vue";
import { NoCache } from "@/utils/Decorators";
@Component({
  components: {
    MobileSideMenu,
    MobileSlimNavbar,
    SlimNavbar,
    StickyNavbar,
    UserNavbarDropdown,
  },
})
export default class TheNavbar extends Vue {
  @Ref() readonly mobileSideMenu!: MobileSideMenu;

  get logged() {
    return currentUserStore?.currentUser;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get lotName() {
    return zoneStore.zone?.badge_text;
  }

  get pageTitle() {
    return zoneStore.zone?.page_title;
  }

  get stickyNavbarRightLinks() {
    if (this.isHome) {
      return this.externalLinks;
    } else {
      return this.homeLink;
    }
  }

  get isHome() {
    return this.$isRouteActive("home");
  }

  get stickyNavbarLeftLinks() {
    if (this.logged && !this.isHome) {
      if (this.logged?.isOneOf(["admin", "superadmin"])) {
        return this.adminLinks;
      } else if (this.logged?.isOccUser || this.logged?.isConsipUser) {
        return this.consipOccUserLinks;
      } else if (this.logged?.isCompanyUser) {
        return this.rtiLinks;
      }
      return this.userLinks;
    } else {
      return this.publicLinks;
    }
  }

  get pageSubtitle() {
    return zoneStore.zone?.page_subtitle;
  }

  get sideMenuLinks(): NavbarItem[] {
    switch (this.$route.name) {
      case "home":
        return [...this.publicLinks, ...this.externalLinks];
      case "faq":
        return [...this.publicLinks, ...this.externalLinks];
      case "info_home":
        if (this.logged?.isOneOf(["admin", "superadmin", "company_user"])) {
          return [...this.infoPageLinks, ...this.adminLinks];
        } else if (this.logged.isOccUser) {
          return [
            this.watcherAreaLink,
            {
              label: "Area fornitura",
              route: { name: "monitoring_post_feed" },
              activeRoutes: [
                "monitoring_post_feed",
                "monitoring_initiative_map",
              ],
              active: false,
              hidden: false,
            },
          ];
        }
        return [...this.userLinks, ...this.infoPageLinks];
      default:
        if (this.logged?.isOneOf(["admin", "superadmin", "company_user"])) {
          return [...this.adminLinks];
        } else if (this.logged?.isOccUser) {
          return [this.watcherAreaLink];
        } else {
          return [...this.publicLinks];
        }
    }
  }

  private homeLink: NavbarItem[] = [
    {
      label: "Torna alla home",
      route: { name: "home" },
      icon: "home",
      active: false,
    },
  ];

  private secureHomeLink: NavbarItem[] = [
    {
      label: "Torna all'area riservata",
      route: { name: "info_home" },
      active: false,
    },
  ];

  private upperNavbarLinks: NavbarItem[] = [
    {
      label: "Contattaci",
      route: { name: "contact-us" },
      active: false,
    },
    {
      label: "Chi siamo",
      route: { name: "chi-siamo" },
      active: false,
    },
  ];

  private externalLinks: NavbarItem[] = [
    {
      label: "FAQ",
      route: { name: "faq" },
      active: false,
      separatorBefore: true,
    },
  ];

  get watcherAreaLink(): any {
    return {
      label: "Area Osservatori",
      route: { name: "watcher_area_home" },
      activeRoutes: [],
      active: false,
    };
  }

  private adminLinks: NavbarItem[] = [
    {
      label: "Configurazione servizi",
      route: { name: "estimate_requests_list" },
      active: false,
      separatorBefore: true,
    },
    {
      label: "Attivazione Iniziative",
      route: { name: "admin_initiatives_list" },
      activeRoutes: ["admin_initiative_show", "initiative_show"],
      active: false,
    },
    {
      label: "Gestione Iniziative",
      route: { name: "admin_contracts_list" },
      activeRoutes: ["admin_contract_show", "contract_show"],
      active: false,
    },
    {
      label: "Area monitoraggio",
      route: { name: "monitoring_post_feed" },
      activeRoutes: ["monitoring_post_feed", "monitoring_initiative_map"],
      active: false,
      hidden: false,
    },
    this.watcherAreaLink,
  ];

  private rtiLinks: NavbarItem[] = [
    {
      label: "Configurazione servizi",
      route: { name: "estimate_requests_list" },
      active: false,
      separatorBefore: true,
    },
    {
      label: "Attivazione Iniziative",
      route: { name: "admin_initiatives_list" },
      activeRoutes: ["admin_initiative_show", "initiative_show"],
      active: false,
    },
    {
      label: "Gestione Iniziative",
      route: { name: "admin_contracts_list" },
      activeRoutes: ["admin_contract_show", "contract_show"],
      active: false,
    },
  ];

  private userLinks: NavbarItem[] = [
    {
      label: "Area informativa",
      route: { name: "info_home" },
      activeRoutes: [
        "info_configurator",
        "documents",
        "event_list",
        "service_show",
      ],
      active: false,
    },
    {
      label: "Project Management",
      route: { name: "project_management_home" },
      activeRoutes: ["project_management_home"],
      active: false,
    },
    {
      label: "Area monitoraggio",
      route: { name: "monitoring_post_feed" },
      activeRoutes: ["monitoring_post_feed", "monitoring_initiative_map"],
      active: false,
      hidden: false,
    },
  ];

  private consipOccUserLinks: NavbarItem[] = [
    {
      label: "Project Management",
      route: { name: "project_management_home" },
      activeRoutes: ["project_management_home"],
      active: false,
    },
  ];

  private publicLinks: NavbarItem[] = [
    {
      label: "Accordo quadro",
      route: { name: "home" },
      section: "#framework-agreement",
      active: false,
    },
    {
      label: "I numeri",
      route: { name: "home" },
      section: "#dashboard",
      active: false,
    },
    {
      label: "News",
      route: { name: "home" },
      section: "#news",
      active: false,
    },
  ];

  private infoPageLinks: NavbarItem[] = [
    {
      label: "Modello operativo",
      route: { name: "home_info" },
      section: "#business-model",
      active: false,
    },
    {
      label: "Catalogo servizi",
      route: { name: "home_info" },
      section: "#services-catalogue",
      active: false,
    },
    {
      label: "Configuratore",
      route: { name: "home_info" },
      section: "#configurator",
      active: false,
    },
    {
      label: "Biblioteca digitale",
      route: { name: "home_info" },
      section: "#digital-library",
      active: false,
    },
    {
      label: "Eventi",
      route: { name: "home_info" },
      section: "#events",
      active: false,
    },
    // {
    //   label: "PA Digital Stories",
    //   route: { name: "home_info" },
    //   section: "#digital-stories",
    //   active: false,
    // },
  ];

  get logo() {
    return require("@/assets/icons/logo.png");
  }

  private openSideMenu() {
    this.mobileSideMenu?.show();
  }
}
